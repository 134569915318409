/* keep this outside #page-header so it can also be picked up by MobileMenu.vue */
div.c-primaryLogo {
    font-size: 3rem;
    font-weight: bold;

    svg {
        height: 66px;
    }

    a {
        color: var(--dark);
        text-decoration: none;
    }
}

#page-header {
    background: transparent;
    transition: background 0.3s ease;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 15;
    max-width: 1920px;
    opacity : 1;

    .container {
        @include desktop-up {
            max-width: calc( 100% - 16rem );
            width: $widescreen-width;
        }

        @include tablet {
            max-width: calc( 100% - 4rem );
            width: 100%;
        }
    }

    &.home {
        position: sticky;
        
    }

    &.home,
    &.blog {
        background: #fff !important;
    }

    body.scrolled-200 & {
        background: #fff;
        border-bottom: 2px solid var(--background);
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        @include mobile {
            padding: 5px 0;
        }
    }

    nav {
        justify-self: flex-end;
        text-align: right;
    }
}

nav.c-primary {
    ul {
        display: flex;
        align-items: center;

        @include tablet-down {
            display: none;
        }
    }

    li {
        margin-left: 1rem;
        position: relative;

        a:not(.button) {
            display: flex;
            height: 40px;
            align-items: center;
            justify-content: center;
            font-size: 2.5rem;
            line-height: 1em;
            color: var(--dark);
            font-family: var(--headline);
            text-decoration: none;
            position: relative;
            padding: 1rem;

            span {
                display: block;
                line-height: 1em;
                
                &.icon {
                    padding: 0rem 1rem;
                }
            }

            &.active {
                &.link:after{
                    width: calc(100% - 2rem) !important;
                    left: 1rem !important;
                }
            }

            &.link:after{
                content: '';
                position: absolute;
                width: 0;
                height: 4px;
                display: block;
                margin-top: 4rem;
                right: 0;
                background: var(--dark);
                transition: width .3s ease;
                -webkit-transition: width .3s ease;
            }
               
            &.link:hover:after{
                width: calc(100% - 2rem);
                left: 1rem;
                background: var(--dark);                
            }

            &.search {
                svg {
                    max-height: 24px;
                    min-height: 24px;
                    width: 24px;
                    fill: var(--dark);
                }
            }

            &.button {
                padding: 0 1rem;
            }
        }

        &.moremenu {
            width: 40px;
            height: 40px;
        }
        
        a.button {
            text-shadow: 2px 2px 0px darken(#ff5800, 10%);
        }

        &:last-of-type {
            margin-left: 1.5rem;
        }
    }
}