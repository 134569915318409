
.c-lookingForSomething {
    //border-top: 10rem solid var(--background);
    position: relative;
    z-index: 12;
    
    .c-block {
        padding-bottom: 9rem;

        .boxBG {
            @include tablet-down {
                flex-direction: column;
            }

            .left, 
            .right {
                @include tablet-down {
                    min-width: 100% !important;
                    max-width: 100% !important;
                }

                h3 {
                    @include tablet-down {
                        text-align: center;
                        margin-bottom: 3rem !important;
                    }

                    @include phablet-down {
                        text-align: center;
                        margin-bottom: 0rem !important;
                    }                    
                }
            }
        }

        @include phablet-down {
            padding-bottom: 4rem;
        }
    }

    .text-wrapper {
        @include phablet-down {
            padding: 0rem 1rem;
        }
    }

    .c-searchForm {
        padding-top: 1rem;

        .wrapper {
            flex-direction: row;
        }

        div.input {
            flex-grow: 1;       
        }

        input[type=search] {
            height: 7rem;
            padding-left: 2rem;
            border-radius: 0 0 0 2rem;
            -webkit-appearance: none;

            @include phablet-down {
                width: 100%;
            }

        }

        button {
            height: 7rem;
            min-width: 13rem;
            border-radius: 0 2rem 2rem 0;
            padding: 1rem 1.5rem 1rem 1rem;

            @include phablet-down {
                min-width: 5rem;
            }
        }
    }

    h3 {
        text-align: right;

        @include phablet-down {
            text-align: center;
            margin-bottom: 2rem;
            font-size: 3.5rem;
        }

        @include mobile {
            font-size: 3rem;
        }
    }

    h4 {
        margin-top: 8rem;
        margin-bottom: 2rem;

        @include phablet-down {
            margin-top: 4rem;
            margin-bottom: 4rem;
        }
        
    }

    ul {
        padding-left: 0 !important;

        li {
            list-style: none;
            margin-bottom: 1.25rem !important;

            a {
                color: var(--bunker);
                font-weight: bold;
                text-decoration: none !important;
                display: inline-block;
                padding: 0.3rem 0.8rem;


                color: var(--text);
                background: #dbe9e4;
                text-decoration-line: underline;
                text-decoration-style: solid;
                text-decoration-thickness: 0.05em;
                text-decoration-skip: edges;
        
                &:hover, &:focus {
                    background: #7daf9a;
                    color: #fff;
                    text-decoration: none;
                }


            }
        }
    }
}