/**
 * components/global.css
 *
 * Project-wide variables & mixins
 *
 */


 :root {
  --dark: #414141;
  --lessdark: #676767;
  --grey: #E6E7E8;
  --background: #f1f1f1;
  --blonde: #FFFFB3;
  --bunker: #162426;
  --beige: #E2E3DD;
  --darkbeige: #d2d3ca;
  --orange: #ff5800;
  --red: #F21D44;
  //--blue:#4D9EB3;
  //--blue:#16A1C0;
  --blue:#1DB2D3;
  //--blue:#4dc1ff;
  --cyan: #b4c9be;
  //--cyan: #acccbf;
  --blueRGB: 77, 193, 255;
  --green:#00ff58;
  --headline: 'Fira Sans', arial, sans-serif;
  --headlineWeight: 900;
  --text: 'Montserrat', times, serif;
  --ease: cubic-bezier(0.16, 1, 0.3, 1);
}


@function color($color-name) {
  @return var(--#{$color-name});
}



// Breakpoints
// -------------------------
$widescreen-width  : 1600px;
$desktop-width     : 1280px;
$legacy-width      : 1020px;
$tablet-width      : 760px;
$mobile-width      : 576px;
$small-mobile-width: 360px;

@mixin ultrawide {
  @media screen and (min-width: 1921px) {
      @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: #{$widescreen-width}) {
      @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop-width}) and (max-width: #{$widescreen-width - 1px}) {
      @content;
  }
}

@mixin desktop-up {
  @media screen and (min-width: #{$desktop-width}) {
      @content;
  }
}

@mixin legacy {
  @media screen and (min-width: #{$legacy-width}) and (max-width: #{$desktop-width - 1px }) {
      @content;
  }
}

@mixin legacy-up {
  @media screen and (min-width: #{$legacy-width}) {
      @content;
  }
}

@mixin legacy-down {
  @media screen and (max-width: #{$desktop-width - 1px}) {
      @content;
  }
}

@mixin tablet-up {
  @media screen and (min-width: #{$mobile-width + 1px}) {
      @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{$mobile-width + 1px}) and (max-width: #{$legacy-width - 1px}) {
      @content;
  }
}

@mixin tablet-down {
  @media screen and (max-width: #{$legacy-width - 1px}) {
      @content;
  }
}

@mixin phablet {
  @media screen and (min-width: #{$mobile-width + 1px}) and (max-width: #{$tablet-width}) {
      @content;
  }
}

@mixin phablet-down {
  @media screen and (max-width: #{$tablet-width - 1px}) {
      @content;
  }
}

@mixin mobile {
  @media screen and (max-width: #{$mobile-width}) {
      @content;
  }
}

@mixin tiny {
  @media screen and (max-width: #{$small-mobile-width}) {
      @content;
  }
}

@mixin laptop {
  @media screen 
    and (min-width: 1200px) 
    and (max-width: 1600px)
    and (max-height: 800px) {
      @content;
    }
}

@mixin shortscreen {
  @media screen 
    and (min-width: #{$tablet-width}) 
    and (max-height: 620px) {
      @content;
    }
}

.blockMeta {
  display: flex;
  justify-content: center;
  align-items: center;
  
  .blockMetaWrapper {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background: rgba(255,255,255,0.75);
      border: 2px dotted #e12d39;
      width: 100%;
      max-width: 740px;
      margin-top: 5rem;

      div {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        font-size: 14px;
        text-align: center;
        color: #000 !important;

        &.type {
          font-size: 28px;
          justify-self: self-start;
        }

        strong {
          font-weight: bold;
          font-size: 18px;
        }

        span {
          
        }

        a {
          color: #e12d39;
        }
      }
  }
}







































































































































@keyframes menuIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

.c-mobileMenu {
    position: relative;
    z-index: 100000;

    .c-primaryLogo {
        margin-left: 1rem;
        padding-top: 1rem;
        
        a {
            color: #fff;
        }
    }

    nav {
        display: flex;
        flex-direction: column;
        opacity: 0;
        max-height: 100vh;
        z-index: 1000;
        position: fixed;
        overflow: auto;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--bunker);
        animation-name: menuIn;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        @include tablet-down {
            min-height: 100vh;
            max-height: 100vh;
        }

        .logo {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 60px;
            z-index: 5;
        }

        .menuWrapper {
            display: flex;
            justify-content: center;
            flex-grow: 1;
            padding-top: 15rem;

            @include tiny {
                padding-top: 12rem;
            }
        }

        ul.primary {
            display: flex;
            flex-direction: column;
            align-items: center;

            & > li {
                margin-bottom: 2rem;
                text-align: center;

                &:last-of-type {
                    margin-bottom: 0em;
                }

                a {
                    font-size: 3rem;
                    display: block;
                    text-decoration: none;
                    color: #fff;
                    font-family: var(--headline);

                    &:focus,
                    &:hover {
                        color: var(--orange);
                    }
                }
            }
        }

        ul.second {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            li {
                margin: 1.5rem 1rem;
                
                a {
                    display: block;
                    font-size: 2rem;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }

        li.search {
            font-size: 1.8rem;
            padding: 0 2rem;

            .wrapper {
                display: flex;

                div.input {
                    flex-grow: 1;
                }
            }

            label {
                font-weight: bold;
                display: block;
            }

            
            input[type=search] {
                font-size: 1.8rem;
                line-height: 1em;
                height: 5rem;
                padding: 1rem;
                border: 1px solid var(--cyan);
                border-right: none;
                width: 100%;
                border-radius: 0 0 0 1.5rem;
                -webkit-appearance: none;
            }
                
            button {
                background: var(--cyan);
                color: var(--dark);
                border: none;
                padding: 1rem;
                height: 5rem;
                width: 9rem;
                border-radius: 0 1.5rem 1.5rem 0;

                &:focus,
                &:hover {
                    background: var(--orange);
                    color: #fff;
                }
            }
        }
    }

    .toggle {
        display : none;
        z-index: 1001;

        @include tablet-down {
            top       : 40px;
            display   : block;
            position  : fixed;
            right     : 20px;
            height    : 24px;
            width     : 30px;
            transform : translateY(0px);
            transition: transform 0.3s;

            body.has-emergency-message & {
                transform: translateY(60px);
            }
        }

        @include mobile {
            top       : 18px;
            right     : 20px;
            height    : 20px;
            width     : 26px;
        }

        @include tiny {
            right: 10px;
        }

        span {
            height          : 4px;
            width           : 30px;
            position        : absolute;
            left            : 0;
            top             : 0;
            background-color: var(--dark);
            transition      : transform 0.3s ease-in-out, background-color 0.3s ease-in-out;

            @include mobile {
                width       : 26px;
            }

            &:nth-child(1) {
                body.has-mobile-menu & {
                    background-color: transparent;
                }
            }

            &:nth-child(2) {
                top: calc( 50% - 2px );

                body.has-mobile-menu & {
                    top   : 10px;
                    transform: rotate(-45deg);
                    background-color: #fff;
                }
            }

            &:nth-child(3) {
                bottom: 0;
                top   : auto;

                body.has-mobile-menu & {
                    background-color: #fff;
                    transform: rotate(45deg);
                    bottom: auto;
                    top   : 10px;
                }
            }
        }
    }
}
