/**
 * pages/contact.scss
 *
 * Styles for contact page
 *
 */

.page--contact {

    h2 {
        margin-top: 0;
    }

    .container {
        @include desktop-up {
            max-width: 1000px;
            margin-right: auto;
            margin-left: auto;

            .container { 
                width: 100%;
            }
        }
    
        @include legacy {
            max-width: calc( 100% - 60px );
            margin-right: auto;
            margin-left: auto;
        }
    }

}


.contact-wrapper {

    p.intro {
        display: none;

        @include tablet-down {
            display: block;
        }
    }

    a.contact-email {
        color: var(--orange);
        font-weight: 600;
    }

    .form-wrapper {
        padding: 5rem;
        background: var(--blue);
        transform: translateX(-8rem);
        border-radius: 0 2rem 2rem 0;
        position: relative;

        &::before {
            background: var(--blue);
            top: 0;
            left: -100%;
            width: 100%;
            content: '';
            height: 100%;
            display: block;
            position: absolute;
        }

        @include legacy {
            transform: translateX(-2rem);
        }

        @include tablet {
            transform: translateX(0);
            padding: 4rem;
        }

        @include phablet-down {
            transform: translateX(0);
            padding: 3rem;
        }
    }

    .form {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5rem;

        @include tablet-down {
            flex-direction: column;
        }
        
        .left {
            max-width: calc( 60% - 4rem );
            min-width: calc( 60% - 4rem );

            @include tablet {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
            }

            @include legacy {
                max-width: calc( 60% - 10rem );
                min-width: calc( 60% - 10rem );
                padding-left: 2rem;
            }
            
            @include full-width-tablet-down;

            p.lead {
                @include tablet-down {
                    display: none;
                }
            }
        }

        .right {
            max-width: calc( 40% + 8rem );
            min-width: calc( 40% + 8rem );

            @include full-width-tablet-down;
        }

        p.lead {
            padding-top: 2rem;
        }

        h3 {
            margin-top: 6rem;
        }

        div.input {
            margin-bottom: 3rem;

            label {
                display: block;
                font-family: var(--headline);
                font-weight: normal;
                font-size: 2.25rem;
                color: #000;
                margin-bottom: 0.5rem;
            }

            input, textarea {
                font-family: var(--text);
                padding: 1.25rem 1rem;
                width: calc( 100% - 2.2rem);
                border: 0;
                border-radius: 0 10px 10px 10px;
            }

            textarea {
                height: 140px;
            }
        }

        button {
            display: block;
            background: var(--bunker);
            padding: 2rem 5rem 2rem 4rem;
            border-radius: 0 10px 10px 10px;
            color: #fff;
            font-size: 2.5rem;
            font-family: var(--headline);
            transition: background-color 0.3s;  

            
            &:focus,
            &:hover {
                background-color: var(--orange);
            }

            &::after {
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                position: relative;
                left: 2rem;
                top: 2px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512.008 512.008'%3E%3Cpath fill='%23fff' d='M508.885 248.468L306.219 45.801c-21.803-21.803-57.28-21.803-79.083 0s-21.803 57.28 0 79.083l77.781 77.781H53.333C23.936 202.665 0 226.601 0 255.999s23.936 53.333 53.333 53.333h251.584l-77.781 77.781c-21.803 21.803-21.803 57.28 0 79.083 10.901 10.901 25.216 16.363 39.552 16.363s28.629-5.44 39.552-16.363l202.667-202.667c4.138-4.16 4.138-10.901-.022-15.061z'/%3E%3C/svg%3E");
            }
        }
    }   
    
    .address-wrapper {
        margin-bottom: 10rem;

        div.map {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            height: 0;
            margin: 0;
            
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 20px;
            }
        }

        .address-wrapper {
            display: flex;
            justify-content: space-between;

            .address, .contacts {
                max-width: calc( 50% - 2rem );
                min-width: calc( 50% - 2rem );

                p {
                    font-size: 1.8rem;
                }
            }
        }
    }
}