nav.c-toc {
    position: absolute;
    height: 100%;

    @include widescreen {
        padding: 0 0 0 12rem;
    }

    @include desktop {
        padding: 0 0 0 8rem;
    }

    @include legacy {
        padding: 0 0 0 2rem;
    }

    @include tablet-down {
        position: relative;
        background: #fff;
        padding: 1rem;
        margin-bottom: 6rem !important;

        div.wrapper {
            position: relative;

            &::before {
                content: 'Table of Contents';
                padding: 0.75rem 0 0.75rem 1rem;
                font-family: var(--headline);
                font-weight: var(--headlineWeight);
                font-size: 3rem;
                display: block;
            }
        }
    }

    div.wrapper {
        position: sticky;
        top: 120px;
        font-size: 1.6rem;
        padding-bottom: 10rem;

        @include widescreen {
            max-width: 260px;
        }

        @include desktop {
            max-width: 200px;
        }

        @include legacy {
            max-width: 160px;
            font-size: 1.4rem;
        }

        @include tablet-down {
            font-size: 1.8rem;
            padding-bottom: 0rem;
        }
    }

    a.title {
        font-weight: bold;
        line-height: 1.6em;
        padding: 0;

        @include tablet-down {
            display: none;
        }
    }

    display: flex;
    flex-direction: column;

    div.children {
        opacity: 0;
        max-height: 0px;
        overflow:hidden;
        transition: max-height 0.3s, opacity 0.3s;

        @include tablet-down {
            display: none !important;
        }
    }

    a.active + div.children {
        opacity: 1;
        max-height: 300px;
    }

    a {
        display: block;
        padding: 0.75rem 0 0.75rem 1rem;
        line-height: 1.4em;
        text-decoration: none;
        color: var(--dark);

        &.level2 {
            font-size: 1.4rem;
            padding: 0.50rem 0 0.50rem 2rem;
            line-height: 1.2em;            
            font-weight: 400;

            &:last-of-type {
                margin-bottom: 0.5rem;
            }
        }

        &.active,
        &:focus,
        &:hover {
            color: var(--orange);
        }
    }
}

.sticky {
    position: sticky;
    top: 120px;
}