html {
    font-size: 62.5%;
    height: -webkit-fill-available;
    scroll-behavior: smooth;

    @include legacy {
        font-size: 55%;
    }

    @include tablet {
        font-size: 55%;
    }
}

body {
    min-height : 100vh;
    min-height : -webkit-fill-available;
    margin     : 0;
	padding    : 0;
	position   : relative;
	font-weight: 400;
    font-family: var(--text);
    font-size  : 1.8rem;
    background: #f1f1f1 !important;
    
    @include tablet-down {
        overflow-x : hidden;
    }

    &.has-mobile-menu {
        overflow: hidden;
        max-height: 100vh;
        min-height: 100vh;
    }

    @include ultrawide {
        background-image: url(https://simplicate.nyc3.digitaloceanspaces.com/simplicate/assets/site/images/body-bg.png);
        background-attachment: fixed;
    }
}

#page-container {
    opacity: 1 !important;
    transition: opacity 0.3s ease-out 0.5s;
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    min-height: 110vh;
    z-index: 10;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    box-shadow: 0 0 10px 3px rgba(33,33,33,.1);    

    #content-container {
        position: relative;
        z-index: 8;
        flex-grow: 1;
    
        #main {
            z-index: 6;
        }
    }
}


div[role=main].page-project,
div[role=main].blog-content,
div[role=main].page--blog-home,
div[role=main].page-content {
    animation-name: contentInUp;
    animation-duration: 0.75s;
    animation-fill-mode: forwards;
    animation-delay: .25s;
}

/* The animation code */
@keyframes contentInUp {
    from { opacity: 0; transform:translateY(50px); }
    to   { opacity: 1; transform:translateY(0px);  }
}


::selection {
	background: var(--blonde); /* WebKit/Blink Browsers */
  }

::-moz-selection {
	background: var(--blonde); /* Gecko Browsers */
}


div.container {
    margin: 0 auto;
    width : 100%;

    /* for testing purposes only */
    //border: 1px dashed rgba(255,0,0,0.25);

    @include desktop-up {
        max-width: calc( 100% - 16rem );
        width: calc( #{$desktop-width} - 8rem );
    }

    @include legacy {
        max-width: calc( 100% - 60px );
    }

    @include tablet {
        max-width: $tablet-width - 20px;
        width    : calc( 100% - 20px );
        margin   : 0 auto;
    }

    @include phablet-down {
        max-width: $mobile-width - 20px;
        width    : calc( 100% - 20px );
        margin   : 0 auto;
    }

    @include tiny {
        min-width: calc( 100% - 12px );
        max-width: calc( 100% - 12px );
        width    : calc( 100% - 12px );
        margin   : 0 auto;
    }

    img {
        max-width: 100%;
    }
}