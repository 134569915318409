.c-block--testimonial {
    
    h2 {
        margin-bottom: 8rem;
        
        &.block-headline {
            text-align: center !important;
        }
    }

    .list {
        .wrapper {
            margin-bottom: 12rem;
       
            &:not(:first-of-type) {

                padding-top: 12rem;
                position : relative;

                &::before {
                    content : '';
                    width: 120px;
                    border-top: 4px dashed var(--dark);
                    height: 1px;
                    display: block;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 0rem;
                    opacity: 0.25;
                }
            }

            
            &:nth-child(even) {
                flex-direction: row-reverse;

                @include phablet-down {
                    flex-direction: column-reverse;
                }

                .author {
                    @include desktop-up {
                        transform: translateX(5rem);
                    }

                    @include legacy-down {
                        transform: translateX(0rem);
                        margin-left: 3rem;
                        margin-right: auto;
                    }

                    @include phablet-down {
                        transform: translateX(0rem);
                        margin-left: auto;
                    }
                }
            }
        }
    }

    .wrapper {
        display: flex;
        align-items: center;
        padding: 0 8rem;

        @include legacy {
            padding: 0 0rem 0 6rem;
        }

        @include tablet {
            padding: 0;
        }

        @include phablet-down {
            flex-direction: column-reverse;
            padding: 0;
        }

        @include mobile {
            padding: 0 1rem;
        }

        .author {
            max-width: 25rem;
            min-width: 25rem;
            align-self: flex-start;
            position: relative;

            @include desktop-up {
                transform: translateX(-5rem);
            }

            @include tablet {
                max-width: 18rem;
                min-width: 18rem;
            }

            @include legacy-down {
                transform: translateX(0rem);
                margin-right: 3rem;
            }

            @include full-width-mobile;
            @include phablet-down {
                transform: translateX(0rem);
                max-width: 240px;
                margin: 3rem auto;
            }

            @include phablet-down {
                justify-content: center;
                text-align: center;
            }

            @include mobile {
                margin: 0rem auto;
            }

            .image {
                align-self: center;
                position: relative;

                @include phablet-down {
                    max-width: 300px;
                    margin: 0 auto;
                }

                @include mobile {
                    // padding: 0 4rem;
                    max-width: calc( 300px - 8rem );
                    min-width: calc( 300px - 8rem );
                }

                &:after {
                    position: absolute;
                    content: '';
                    border-radius: 30% 30% 30% 0;
                    background: var(--cyan);
                    display: block;
                    height: 100%;
                    width: 100%;
                    bottom: -0.5rem;
                    left: -0.5rem; 
                }
            }

            img {
                max-width: 100%;
                min-width: 100%;
                border-radius: 30% 30% 30% 0;
                position: relative;
                z-index: 2;
                display: block;
                width: 100%;
                height: auto;
            }

            .info {
                text-align: center;
                padding-top: 1rem;

                .name {
                    display: block;
                    padding: 0.4rem;

                    a {
                        &:hover {
                            svg {
                                fill: var(--blue) !important;
                            }
                        }

                        &::before {
                            content: '';
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            display: block;
                            position: absolute;
                            z-index: 5;
                        }
                    }

                    svg {
                        max-height: 1.5rem;
                        max-width: 1.5rem;
                        fill: #fff;
                        transform: translate(2px, 1px);

                        &.dark {
                            fill: var(--bunker);
                        }
                    }
                }

                .title {
                    font-weight: 300;
                    font-size: 1.5rem;

                    @include tablet {
                        font-size: 1.2rem;
                    }

                    @include phablet {
                        font-size: 1.4rem;
                    }
                }
            }
        }

        .text {
            transform: translateY(-2rem);

            @include phablet-down {
                transform: translateY(0rem);
                padding-bottom: 3rem;
            }

            .title,
            .title p {
                font-size: 3.5rem;
                line-height: 1.3em;
                margin-bottom: 1.5rem;
                font-family: var(--headline);
                letter-spacing: -0.02em;
                font-weight: 500;
                max-width: 740px;

                @include legacy-down {
                    max-width: 680px;
                }

                @include no-mobile-brs;

                @include phablet-down {
                    font-size: 2.8rem;
                }

                strong {
                    letter-spacing: 0;
                    font-weight: 900;
                }
            }

            div.quote {
                p {
                    font-size: 2.0rem;

                    @include legacy-down {
                        max-width: 650px;
                    }

                    @include mobile {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }

    &.layout-reverse {
        .wrapper {
            flex-direction: row-reverse;

            @include legacy-down {
                padding: 0 8rem 0 0;
            }

            @include tablet {
                padding: 0;
            }

            @include phablet-down {
                flex-direction: column-reverse;
                padding: 0;
            }

            @include mobile {
                padding: 0 10px;
            }
    
            .author {
                @include desktop-up {
                    transform: translateX(5rem);
                }

                @include phablet-down {
                    transform: translateX(0rem);
                }
            }

            .text {
                @include tablet {
                    padding-right: 4rem;
                }

                @include phablet-down {
                    padding-right: 0rem;
                }
            }
        }
    }
}