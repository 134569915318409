.c-block--text {
    &.spacing-tight {
     
        .header-wrapper {
            h2 {
                margin-bottom: 3rem;
            }
        }

        .text-wrapper {
            opacity: 0;
            transform: translateY(50px);
            transition : opacity 0.3s linear 0.5s,
                         transform 0.3s linear 0.5s;

            h3 {
                @include tablet {
                    max-width: 540px;
                    margin: 0 auto;
                }
            }

            h4 {
                max-width: 740px;
                margin: 0 auto 1.5em;

                @include tablet {
                    max-width: 540px;
                }                
            }

            ul {
                max-width: 740px;
                margin: 0 auto 1.5em;

                @include tablet {
                    max-width: 540px;
                }
            }

            p {
                max-width: 740px;
                margin: 0 auto 1.5em;

                @include tablet {
                    max-width: 540px;
                }
            }

            p.lead {
                max-width: 740px;
                margin: 0 auto 1.5em;

                @include tablet {
                    max-width: 540px;
                }

                @include mobile {
                    br  {
                        content: ' '
                    }
                    br:after {
                        content: ' '
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            &.active {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}