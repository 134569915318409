/**
 * components/global.css
 *
 * Project-wide variables & mixins
 *
 */


 :root {
  --dark: #414141;
  --lessdark: #676767;
  --grey: #E6E7E8;
  --background: #f1f1f1;
  --blonde: #FFFFB3;
  --bunker: #162426;
  --beige: #E2E3DD;
  --darkbeige: #d2d3ca;
  --orange: #ff5800;
  --red: #F21D44;
  //--blue:#4D9EB3;
  //--blue:#16A1C0;
  --blue:#1DB2D3;
  //--blue:#4dc1ff;
  --cyan: #b4c9be;
  //--cyan: #acccbf;
  --blueRGB: 77, 193, 255;
  --green:#00ff58;
  --headline: 'Fira Sans', arial, sans-serif;
  --headlineWeight: 900;
  --text: 'Montserrat', times, serif;
  --ease: cubic-bezier(0.16, 1, 0.3, 1);
}


@function color($color-name) {
  @return var(--#{$color-name});
}



// Breakpoints
// -------------------------
$widescreen-width  : 1600px;
$desktop-width     : 1280px;
$legacy-width      : 1020px;
$tablet-width      : 760px;
$mobile-width      : 576px;
$small-mobile-width: 360px;

@mixin ultrawide {
  @media screen and (min-width: 1921px) {
      @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: #{$widescreen-width}) {
      @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop-width}) and (max-width: #{$widescreen-width - 1px}) {
      @content;
  }
}

@mixin desktop-up {
  @media screen and (min-width: #{$desktop-width}) {
      @content;
  }
}

@mixin legacy {
  @media screen and (min-width: #{$legacy-width}) and (max-width: #{$desktop-width - 1px }) {
      @content;
  }
}

@mixin legacy-up {
  @media screen and (min-width: #{$legacy-width}) {
      @content;
  }
}

@mixin legacy-down {
  @media screen and (max-width: #{$desktop-width - 1px}) {
      @content;
  }
}

@mixin tablet-up {
  @media screen and (min-width: #{$mobile-width + 1px}) {
      @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{$mobile-width + 1px}) and (max-width: #{$legacy-width - 1px}) {
      @content;
  }
}

@mixin tablet-down {
  @media screen and (max-width: #{$legacy-width - 1px}) {
      @content;
  }
}

@mixin phablet {
  @media screen and (min-width: #{$mobile-width + 1px}) and (max-width: #{$tablet-width}) {
      @content;
  }
}

@mixin phablet-down {
  @media screen and (max-width: #{$tablet-width - 1px}) {
      @content;
  }
}

@mixin mobile {
  @media screen and (max-width: #{$mobile-width}) {
      @content;
  }
}

@mixin tiny {
  @media screen and (max-width: #{$small-mobile-width}) {
      @content;
  }
}

@mixin laptop {
  @media screen 
    and (min-width: 1200px) 
    and (max-width: 1600px)
    and (max-height: 800px) {
      @content;
    }
}

@mixin shortscreen {
  @media screen 
    and (min-width: #{$tablet-width}) 
    and (max-height: 620px) {
      @content;
    }
}

.blockMeta {
  display: flex;
  justify-content: center;
  align-items: center;
  
  .blockMetaWrapper {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background: rgba(255,255,255,0.75);
      border: 2px dotted #e12d39;
      width: 100%;
      max-width: 740px;
      margin-top: 5rem;

      div {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        font-size: 14px;
        text-align: center;
        color: #000 !important;

        &.type {
          font-size: 28px;
          justify-self: self-start;
        }

        strong {
          font-weight: bold;
          font-size: 18px;
        }

        span {
          
        }

        a {
          color: #e12d39;
        }
      }
  }
}










































#emergency-message {
    background: #000;
    display: flex;
    max-height: 60px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.15s linear;
    position: relative;
    z-index: 9999;
    
    p {
        margin: 0;
        padding: 20px 0;
        font-weight: bold;
        color: #fff;
        text-align : center;
        flex-grow: 1;

        a {
            color: var(--blonde);
            display: inline-block;
            margin-left: 2px;

            &:focus,
            &:hover {
                color: var(--red);
            }

            &::after {
                content: '›';
                display: inline-block;
                margin-left: 3px;
            }
        }
    }

    body.scrolled & {
        max-height: 0;
    }

    &.hidden {
        max-height: 0 !important;
        opacity: 0;
    }

    .close {
        position: absolute;
        right: 22px;
        top: 18px;
        width: 22px;
        height: 22px;
        opacity: 0.8;
        z-index: 100000;

        &:focus,
        &:hover {
            opacity: 1;

            &:before, &:after {
                background-color: var(--red);
            }

        }

        &:before, &:after {
            position: absolute;
            left: 9px;
            content: '';
            height: 22px;
            width: 4px;
            background-color: #fff;
            z-index: 1000;
        }

        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}
