/**
 * pagination.css
 *
 */

.c-pagination {
    text-align: center;
    font-size: 2rem;
    margin: 10rem 0;
    position: relative;
    display: flex;
    justify-content: center;

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    li {
        padding: 0 3rem;
        min-width: 80px;

        @include mobile {
            &.pageCount {
                position: absolute;
                bottom: -6rem;
                width: 100%;
                left: 0;
                margin: 0;
                padding: 0;
            }

            &.empty {
                display: none;
            }
        }
    }
}


nav.project-pagination {
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0rem 0 4rem;
    padding: 0rem 0;   
}