.c-searchForm {
    font-size: 1.8rem;

    @include phablet-down {
        font-size: 1.6rem;
    }

    .wrapper {
        display: flex;

        div.input {
            
        }
    }

    label {
        font-weight: bold;
        display: block;
    }
    
    input[type=search] {
        min-width: 20rem;
        font-size: 1.8rem;
        line-height: 1em;
        height: 5rem;
        padding: 1rem;
        border: 1px solid var(--bunker);
        border-right: none;
        width: 100%;
        border-radius: 0 0 0 1.5rem;
        -webkit-appearance: none;

        @include tablet-up {
            min-width: 40rem;
        }
        
        @include phablet-down {
            min-width: calc( 100% - 9rem );
            font-size: 1.6rem;
        }
    }
        
    button {
        background: var(--bunker);
        color: #fff;
        border: none;
        padding: 1rem;
        height: 5rem;
        width: 9rem;
        border-radius: 0 1.5rem 1.5rem 0;

        &:focus,
        &:hover {
            background: #000;
        }
    }
}

.page-search {
    margin-top: 0rem;
}

.page-search,
.section-search {

    .container { 
        max-width: 740px;
    }

    h2 {
        text-align: center;
        font-size: 3rem;
    }

    .c-searchForm {
        max-width: 580px;
        margin: 0 auto;
        div.input {
            flex-grow: 1;
        }
    }

    .c-searchResults {
        padding-top: 5rem;
        padding-bottom: 10rem;
        overflow-wrap: break-word;

        h2, h3 {
            text-align: left;
        }
        text-align: left;
    }
}