.c-skipLink {
	background: #000;
	position: absolute;
	transform: translateY(-100%);
	transition: transform 0.3s;
	width: 100%;
	left: 0;
	z-index: 10000;
	text-align: center;
	min-height: 42px;
	padding: 8px 0;
	color: #fff;
	display : flex;
	align-items: center;
	justify-content: center;

	a {
	    display: inline-block;
	    color: var(--red);
	    font-weight: 700;
	    padding: 4px;
	    text-decoration: none;
	}

	&:focus-within,
	&:focus {
		transform: translateY(0%);
		opacity: 1 !important;
	}
}
