/**
 * pages/pages.pcss
 *
 * Styles for default pages
 *
 */

 .page--blog-home,
 .page-search,
 .page-project,
 .page-content {
    position: relative;

    &::after {
        position: absolute;
        height: 50px;
        width: 100%;
        top: calc( -10rem - 1px );
        left: 0;
        display: block;
        content: '';
        z-index: 100;
        background: rgb(247,254,254);
        background: linear-gradient(180deg, rgba(247,254,254,1) 0%, rgba(241,241,241,1) 100%);

        @include phablet-down {
            top: calc( -4rem - 1px );
            height: 25px;
        }
    }
}

.page--404 {

    p.lead {
        margin-bottom: 10rem;
    }

    .container {
        max-width: 740px;
    }
}

.page--terms,
.page--privacy {

    .container {
        max-width: 740px;
    }

}