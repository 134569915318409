.c-block--blogPosts {
    
    &.spacing-wide {
        div.container {
            @include desktop-up {
                max-width: $widescreen-width;
            }
        }
    }

    &.spacing-skinny {
        max-width: 1000px;
        min-width: 1000px;
        margin: 0 auto 10rem;
    }

    h2 {
        margin-bottom: 8rem;
        text-align: center;

        &.block-headline {
            @include phablet-down {
                text-align: center !important;
            }
        }
    }

    div.articles {
        article {
            opacity : 0;
            transform: translateY(100px);
        }
    }

    &.active {
        div.articles {
            article {
                opacity: 1;
                transform: translateY(0px);
                transition: opacity 0.5s linear 0.25s, transform 0.5s ease 0.25s;

                &:nth-of-type(2) {
                    transition-delay: 0.65s;
                }

                &:nth-of-type(3) {
                    transition-delay: 1.05s;
                }
            }
        }
    }
}