h2#our-wheelhouse {
    text-align: center !important;
}


.c-block--textImage {

    &.layout-normalCenter,
    &.layout-reverseCenter {
        .wrapper {
            align-items : center;

            &.many-images {
                margin-bottom: 5rem;

                @include tablet {
                    margin-bottom: 5rem;
                }
    
                @include phablet-down {
                    margin-bottom: 10rem;
                }

                .image {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.layout-largeImageSticky {
        .wrapper {

            @include phablet-down {
                flex-direction: column;

                .text-wrapper {
                    margin-bottom: 5rem;
                }
            }

        }
    }

    .wrapper {
        display: flex;

        @include phablet-down {
            flex-direction: column-reverse;
        }
    }

    .text {
        position: relative;
        z-index: 3;
        flex-grow: 1;

        .inner {
            position: relative;
            z-index: 4;

            .text-wrapper {
                h2:first-child, h3:first-child {
                    margin-top: -0.75rem !important;
                }

                h3 {
                    &.hasEyebrow {
                        margin-top: 3rem !important;
                    }
                }

                .iconRow {
                    h3 {
                        @include mobile {
                            padding-right: 120px;
                            margin-top: -40px !important;
                        }
                    }
                }


            }
        }
    }

    .image {
        min-width: 37%;
        max-width: 37%;
        text-align: right;
        position: relative;
        z-index: 3;
        margin-bottom: 5rem;

        @include full-width-mobile;
        @include full-width-phablet-down;

        &.count-1 {
            margin-bottom: 0rem;
            
            @include phablet-down {
                margin-bottom: 3rem;
            }
        }

        &.count-many {

        }

        .picture-wrapper {
            display: none;
            &.active {
                display: block;
            }
        }

        nav.thumbnails {
            position: absolute;
            left: calc( 50% + 12rem );
            transform : translateX(-50%);

            @include desktop {
                left: calc( 50% + 9rem );
            }

            @include legacy-down {
                left: 50%;
            }

            @include phablet-down {
                left: 50%;
            }

            ul {
                li {
                    padding: 0 !important;

                    &:after {
                        display: none !important;
                    }
            
                }
            }
        }

        img {
            border-radius: 0 2rem 2rem 2rem;
        }

        @include legacy-down {
            margin-left: 4rem;
            margin-right: 0rem;
        }

        @include tablet-down {
            margin-left: 2rem;
        }

        @include phablet-down {
            margin-left: 0;
            margin-bottom: 3rem;
        }

        img {
            transform: translateX(8rem);

            @include desktop {
                transform: translateX(6rem);
            }

            @include legacy-down {
                transform: translateX(0rem);
            }

            @include phablet-down {
                transform: translateX(0rem);
            }
        }
    }


    &.background-darkBox,
    &.background-whiteBox {
        .image {
            img {
                transform: translateX(2rem) !important;

                @include phablet-down {
                    transform: translateX(0rem);
                }
            }
        }

        .text {
            padding: 3rem 2rem 3rem 4rem;

            @include legacy-down {
                padding-right: 0rem;
            }

            @include phablet-down {

            }

            p {
                max-width: 560px;

                @include desktop-up {
                    max-width: 650px;
                }
            }
        }

        &.layout-reverseCenter,
        &.layout-reverse {
            .image {
                img {
                    transform: translateX(-2rem) !important;

                    @include phablet-down {
                        transform: translateX(0rem);
                    }
                }
            }

            .text {
                @include legacy-down {
                    padding-left: 0rem;
                }
            }
        }

        .boxBG {
            display: flex;
            align-items: center;
        }
    }


    &.layout-reverse, 
    &.layout-reverseCenter {
        .image {
            @include tablet-down {
                margin-left: 0;
                margin-right: 4rem;
            }

            @include phablet-down {
                margin-right: 0;
            }
        }
    }

    


    &.layout-reverse,
    &.layout-reverseCenter,
    &.layout-largeImageReverse,
    &.layout-textColumnsReverse {
        .wrapper {
            flex-direction: row-reverse;

            @include phablet-down {
                flex-direction: column-reverse;
            }

            .image {
                text-align: left;

                @include legacy {
                    margin-left: 0rem;
                    margin-right: 4rem;
                }

                nav.thumbnails,
                img {
                    transform: translateX(-6rem);

                    @include legacy-down {
                        transform: translateX(0rem);
                    }

                    @include phablet-down {
                        transform: translateX(0rem);
                    }
                }
            }
        }

        &.background-whiteBox,
        &.background-darkBox {
            .text {
                &::before {
                    left: calc( -37% + 8rem );

                    @include legacy {
                        //left: calc( -37% + 10rem );
                        left: calc( -40% + 8rem );
                    }

                    @include phablet-down {
                        left: 0;
                    }
                }

                &::after {
                    left: calc( -37% + 9rem );

                    @include legacy {
                        left: calc( -40% + 9rem );
                    }

                    @include phablet-down {
                        left: 0;
                    }
                }
            }
        }
    }


    /* normal sized image with stackable columns in the text area */
    &.layout-textColumns,
    &.layout-textColumnsReverse {
        align-items: center;
        
        .wrapper {
            @include tablet-down {
                flex-direction: column-reverse;
            }
        }

        .image {
            @include tablet-down {
                min-width: 100%;
                max-width: 100%;
                margin: -4rem 0 5rem 0;

                @include phablet-down {
                    margin-top: 0;
                }


                div {
                    text-align: center;

                    img {
                        max-width: 500;
                    }
                }
            }
        }

        .text {
            display: block;

            .inner {
                display: flex;
                align-items: center;
                min-height: 100%;

                .text-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
        
                    & > div {
                        width: 100%;
                        max-width: calc( 50% - 4rem );
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 6rem;
                        align-self: flex-start;
                        position: relative;

                        @include legacy-down {
                            max-width: calc( 50% - 2rem );
                        }
                        
                        @include full-width-mobile;

                        @include mobile {
                            padding-left: 3rem;
                            margin-bottom: 0rem;
                            padding-bottom: 4rem;
                            min-width: calc( 100% - 3rem);
                            max-width: calc( 100% - 3rem);
                        }

                        &::after {
                            @include mobile {
                                content: '';
                                position: absolute;
                                left: 0.95rem;
                                top: 3rem;
                                min-height: calc( 100% - 3.5rem );
                                height: calc( 100% - 3.5rem );
                                width: 2px;
                                border-left: 2px dashed #000;
                                opacity: 0.5;
                            }

                        }

                        &:first-child {
                            align-self: flex-start;
                        }

                        &:last-child {
                            &::after {
                                display: none;
                            }
                        }

                        &:nth-last-child(1),
                        &:nth-last-child(2) {
                            //margin-bottom: 0;
                        }

                        &:nth-last-child(2) {
                            @include phablet-down {
                                margin-bottom: 6rem;
                            }

                            @include mobile {
                                margin-bottom: 0;
                            }
                        }

                        &:hover {
                            h3 {
                                @include with-moustache-in;
                            }
                        }

                        h3 {
                            display: flex;
                            align-items: flex-end;
                            margin-top: 0 !important;
                            font-size: 2.5rem;
                            margin-bottom: 1rem !important;
                            
                            @include with-moustache-out;

                            @include tablet {
                                font-size: 3rem;
                            }

                            @include mobile {
                                position: relative;

                                &::before {
                                    position: absolute;
                                    left: -3rem;
                                    top: 0.25rem;
                                    width: 1.8rem;
                                    height: 1.8rem;
                                    border: 2px solid #000;
                                    border-radius: 50%;
                                    content: '';
                                    opacity: 0.5;
                                }
                            }
                        }
            
                        p {
                            font-size: 1.8rem;

                            @include tablet {
                                font-size: 2.2rem;
                            }

                            @include phablet-down {
                                font-size: 1.7rem;
                            }
                        }
                    }
                }
            }
        }
    }


    &.layout-largeImage,
    &.layout-largeImageReverse,
    &.layout-largeImageSticky {
        position: relative;

        .image {
            min-width: 70%;
            max-width: 70%;
            text-align: right;
        }

        .text {
            ul {
                margin: 0 0 1.5rem 0;
                padding: 0 0 0 1em;
            }
        }

        &.spacing-normal {
            .text {
                p {
                    font-size: 1.8rem;

                    @include tablet {
                        font-size: 1.6rem;
                    }
    
                    &.lead {
                        font-size: 2.2rem;

                        @include legacy-down {
                            font-size: 2rem;
                        }
                    }
                }
    
                li {
                    font-size: 1.8rem;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }

    &.layout-largeImage,
    &.layout-largeImageReverse {

        .wrapper {
            @include tablet {
                flex-direction: column;
            }
        }

        .header-wrapper {
            @include tablet {
                max-width: 540px;
                margin: 0 auto;
            }            
        }

        .text {
            @include tablet {
                max-width: 540px;
                margin: 0 auto 5rem;
            }
        }

        .image {
            @include full-width-mobile;

            @include tablet {
                max-width: 540px;
                margin: 0 auto !important;
                display: flex;
                justify-content: center;
            }            

            &.count-many {
                @include tablet-down {
                    margin-bottom: 5rem;
                }
            }

            @include tablet {
                margin: 0;
            }
        }
    }


    &.layout-largeImageSticky {

        .image {
            margin-bottom: 1.6rem;
            min-width: 65%;
            max-width: 65%;

            @include tablet {
                min-width: 62%;
                max-width: 62%;
            }

            @include full-width-phablet-down;

            @include phablet-down {
                margin-bottom: 5rem;
            }
        }

        .text {
            .inner {
                position : sticky;
                top: 120px;

                @include phablet-down {
                    position: relative;
                    top: 0;
                }
            }
        }
    }


    nav.thumbnails {

        @include phablet-down {
            padding-bottom: 5rem;
        }

        &.images {
            position: relative;

            &.desktop {
                display: none;
                @include legacy-up {
                    display: block;
                }
                @include tablet {
                    display: block;
                }

                @include phablet-down {
                    display: none;
                }
            }

            &.mobile {
                display: none;
                @include phablet-down {
                    display: block;
                }
            }
        }

        // dots
        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;

            li {
                a {
                    padding: 1rem;
                    position: relative;

                    &::after {
                        content: '';
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        z-index: 10;
                    }

                    &:hover,
                    &:focus {
                        span.dot {
                            background-color: var(--dark);
                        }                        
                    }

                    &.active {
                        span.dot {
                            background-color: var(--blue);
                        }
                    }
                }
            }

            span.dot {
                height: 2rem;
                width: 2rem;
                background-color: #bbb;
                border-radius: 50%;
                display: inline-block;
            }
        }

        // image thumbnails
        ol {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 3rem;

            li {
                min-width: calc( 33.33% - 1.6rem );
                max-width: calc( 33.33% - 1.6rem );
                border: 0.3rem solid transparent;
                margin-bottom: 1.6rem !important;

                @include tablet-down {
                    min-width: calc( 33.33% - 0.8rem );
                    max-width: calc( 33.33% - 0.8rem );                            
                    margin-bottom: 0.5rem !important;
                }

                a {
                    display: block;
                    position: relative;

                    &::before {
                        transition: opacity 0.3s ease,  background-color 0.3s ease;
                        opacity: 0;
                        content: '';
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        z-index: 5;
                        border-radius: 0 1rem 1rem 1rem;
                    }

                    &::after {
                        content: '';
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        z-index: 10;
                    }

                    &:hover,
                    &:focus,
                    &.active {
                        figure {
                            figcaption {
                                opacity: 1;
                                transform: translateX(-50%) translateY(-50%);
                            }
                        }
                    }

                    &:hover,
                    &:focus {
                        border-color: var(--dark);
                        &::before {
                            opacity: 1;
                            background-color: rgba(0,0,0, 0.8);
                        }
                    }

                    &.active {
                        border-color: var(--blue);
                        &::before {
                            opacity: 1;
                            background-color: rgba(var(--blueRGB), 0.8);
                        }
                    }
                }

                figure {
                    margin: 0;
                    position: relative;

                    figcaption {
                        transition: opacity 0.3s ease, transform 0.3s ease;
                        opacity: 0;
                        position : absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(0%);
                        font-family: var(--headline);
                        color: #fff;
                        z-index: 10;
                        text-transform: uppercase;
                        line-height: 1em;
                        font-size: 1.4rem;
                        max-width: 90%;

                        @include tablet {
                            font-size: 1.2rem;
                        }

                        @include phablet {
                            font-size: 1.6rem;
                        }

                        @include tiny {
                            font-size: 1.2rem;
                        }
                    }
                }

                img {
                    display: block;
                    margin: 0;
                    padding: 0;
                    line-height: 1em;
                    width: 100%;
                    border-radius: 0 1rem 1rem 1rem;
                }
            }
        }
    }
}