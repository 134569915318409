#textTwo-244,
#textImage-142,
#textImage-3379,
#textTwo-3028,
#textImage-3195,
#textTwo-3081,
.c-block.background-colourBand {   
    div.block-content {
        ul:not(.suggest) {
            padding-left: 0;

            li {
                font-size: 1.6rem;
                margin-bottom: 0.5em;
                list-style-type: none;
                padding: .25em 0 0 2.5em;
                position: relative;

                @include desktop {
                    font-size: 1.8rem;
                }
                
                &:after {
                    content: " ";
                    display: block;
                    width: .3em;
                    height: .65em;
                    border: solid #419f7f;
                    border-width: 0 .25em .25em 0;
                    position: absolute;
                    left: 1em;
                    top: 0.5em;
                    //margin-top: -.2em;
                    -webkit-transform: rotate(40deg);
                    -moz-transform: rotate(40deg);
                    -o-transform: rotate(40deg);
                    transform: rotate(40deg);
                }
            }
        }
    }

    &.c-block.background-colourBand {
        div.block-content {
            ul {   
                li {
                    &:after {
                        border-color: var(--bunker);
                    }
                }
            }
        }
    }
}

ul.checkmarks {
    padding-left: 0;

    li {
        font-size: 1.6rem;
        margin-bottom: 0.5em;
        list-style-type: none;
        padding: .25em 0 0 2.5em;
        position: relative;

        @include desktop {
            font-size: 1.8rem;
        }
        
        &:after {
            content: " ";
            display: block;
            width: .3em;
            height: .65em;
            border: solid #419f7f;
            border-width: 0 .25em .25em 0;
            position: absolute;
            left: 1em;
            top: 0.5em;
            //margin-top: -.2em;
            -webkit-transform: rotate(40deg);
            -moz-transform: rotate(40deg);
            -o-transform: rotate(40deg);
            transform: rotate(40deg);
        }
    }
}





.c-block.background-colourBand +
.c-block.background-colourBand {
    padding-top: 0;
}

.c-block.background-darkBand +
.c-block.background-darkBand {
    padding-top: 0;
    background: var(--bunker);
}

.c-block.background-whiteBand +
.c-block.background-whiteBand {
    padding-top: 0;

    &.spacing-extraTop {
        padding-top: 5rem;
    }
}


.c-block.background-normal +
.c-block.background-normal {
    padding-top: 0;

    &.spacing-extraTop {
        padding-top: 10rem;
    }
}

.c-block.background-normal +
.c-block.background-whiteBox {
    padding-top: 5rem;
}

.c-block.background-whiteBox +
.c-block.background-normal {
    padding-top: 0;
}


.block-container {

    .c-block:first-of-type {
        padding-top: 0rem;

        &.background-darkBand {
            padding-top: 10rem;
        }
    }
}

.c-block {
    padding-top: 10rem;
    padding-bottom: 10rem;

    @include phablet-down {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    h2.block-headline,
    h3.block-headline {
        color: var(--bunker);

        @include no-mobile-brs;
    }

    &.background-whiteBand {
        background: #fff;
    }

    &.background-colourBand {
        background: var(--cyan);
    }

    &.background-darkBand {
        background: var(--background);
        display: flex;
        flex-direction: column;
        color: #fff !important;
        transition: background-color 0.5s linear,
                    min-height 1s linear; 


        @include mobile {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }

        &.active {
            background: var(--bunker);

            .block-content {
                opacity: 1;
                transform: translateY(0px);
            }
        }

        mark {
            background-color: var(--cyan);
        }

        .button {
            &:hover {
                background-color: var(--cyan);
                color: var(--bunker);

                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 292.359 292.359'%3E%3Cpath fill='%23162426' d='M222.979 133.331L95.073 5.424C91.456 1.807 87.178 0 82.226 0s-9.233 1.807-12.85 5.424c-3.617 3.617-5.424 7.898-5.424 12.847v255.813c0 4.948 1.807 9.232 5.424 12.847 3.621 3.617 7.902 5.428 12.85 5.428 4.949 0 9.23-1.811 12.847-5.428l127.906-127.907c3.614-3.613 5.428-7.897 5.428-12.847 0-4.948-1.813-9.229-5.428-12.846z'/%3E%3C/svg%3E");
                }
            }
        }

        @include phablet-down {
            background: var(--bunker);

            .block-content {
                opacity: 1;
                transform: translateY(0px);
            }
        }

        .block-content {
            opacity: 0;
            transform: translateY(50px);
            transition: opacity 0.5s ease, transform 0.5s ease;
        }
    }

    &.background-darkBand {
        h2.block-headline ,
        h3.block-headline  {
            color: #fff !important;
        }
    }

    &.spacing-extraTop {
        padding-top: 15rem;

        @include phablet-down {
            padding-top: 5rem;
        }
    }

    &.spacing-wide {
        div.container {
            @include desktop-up {
                max-width: $widescreen-width;
            }
        }
    }

    &.spacing-skinny {
        div.container {
            @include desktop-up {
                width: calc( 100% - 8rem );
                max-width: calc( #{$legacy-width} - 8rem );
            }
        
            @include legacy {
                max-width: calc( 100% - 8rem );
                max-width: calc( #{$legacy-width} - 8rem );
            }
        }
    }


    &.spacing-extraBottom {
        padding-bottom: 20rem;
    }


    &.background-darkBox,
    &.background-whiteBox {
        padding-bottom: 15rem;
     
        h3.block-headline {
            margin-bottom: 7rem;
        }

        .boxBG {
            &::before {
                z-index: 2;
                position: absolute;
                left: -2rem;
                width: calc( 100% + 20rem );
                height: calc( 100% + 8rem );
                content: '';
                display: block;
                background:#fff;
                border-radius : 2rem;
            }

            &::after {
                z-index: 3;
                position: absolute;
                left: -1rem;
                width: calc( 100% + 18rem - 8px );
                height: calc( 100% + 6rem - 8px );
                content: '';
                display: block;
                border: 4px dashed #ccc;
                border-radius : 2rem;
                opacity: 0.5;
            }
        }   
    }
   

    &.background-darkBox {
        .boxBG {
            &::before {
                background:var(--dark);
            }

            &::after {
                border: 4px dashed #676767;
            }

            h2, h3, h4, h5, h6, p, li, a {
                color: #fff;
            }

            a.callout {
                &:before { 
                    color: var(--orange); 
                }
            }

            p.seeAlso {
                background-color: var(--lessdark);
                border-color: #676767;

                a {      
                    &:focus,
                    &:hover {
                        color: var(--blue) !important;
                    }
                }
            }
        }
    }


    .block-content {
        position: relative;

        h2 {
            margin-top: 0;
            margin-bottom: 9rem;

            @include phablet-down {
                text-align: left !important;
                margin-bottom: 5rem;
            }

            &.h2Center {
                text-align: center !important;

                &:not(.hasEyebrow) {
                    @include mobile {
                        text-align: left !important;
                    }
                }
            }

            &.h3Center {
                text-align: center !important;
                font-size: 4.5rem;
                margin-bottom: 6rem;
            }

            &.h2Left {
                text-align: left;
                @include with-moustache;
            }

            &.hasEyebrow {
                padding-top: 2rem;
            }

        }

        h3 {
            margin-top: 0;
            margin-bottom: 2rem;
            position: relative;

            @include phablet-down {
                text-align: left !important;
            }

            &.h3Center {
                text-align: center !important;
                font-size: 4.5rem;
                margin-bottom: 6rem;
            }

            &.h3Left {
                text-align: left;
            }

            .eyebrow {
                font-size: 1.8rem;
                line-height: 1em;
                text-transform:uppercase;
                display: inline-block;
                margin : 0 auto;
                text-align: center;
                padding: 1rem;
                background: var(--blue);
                color: #fff;
                font-weight: 500;
                transform: rotate(-2deg) translateX(-3rem) translateY(-5.5rem);
                position: absolute;

                @include tablet {
                    transform: rotate(-2deg) translateX(-1rem) translateY(-5.5rem);
                }

                @include phablet-down {
                    transform: rotate(-2deg) translateX(-0.25rem) translateY(-4.5rem);
                }

                @include mobile {
                    transform: rotate(-2deg) translateX(-0.25rem) translateY(-3.5rem);
                    font-size: 1.5rem;
                    padding: 0.5rem;                    
                }
            }
        }

        p.lead {
            @include phablet-down {
                text-align: left !important;
            }
        }

        p + h3,
        ol + h3,
        ul + h3,
        p + div.iconRow,
        ol + div.iconRow,
        ul + div.iconRow,
        div.iconRow + div.iconRow {
            margin-top: 5rem;
        }

        .wrapper {
            .text {
                & > :first-child {
                    margin-top: 0;
                }
            }
        }

        ul:last-child,
        ol:last-child,
        p:last-child {
            margin-bottom: 0 !important;
        }

        p:not(.seeAlso):not(.small),
        li:not(.link) {
            a:not(.button):not(.callout):not(.dot) {
                color: var(--text);
                display: inline;
                text-decoration: none;
                background-image: linear-gradient(120deg, #3fc8e8, #3fc8e8);
                background-size: 100% 2px;
                background-position: 0 95%;
                background-repeat: no-repeat;
                transition: background-size 0.15s linear 0.25s;

                &:hover, &:focus {
                    background-size: 100% 100%;
                }
            }
        }

        ol li {
            a:not(.button):not(.callout):not(.dot) {
                font-weight: 600;
            }
        }
  
        figure {
            display: block;
            width: 100%;
            max-width: 100%;
            margin: 2.5rem 0;
            text-align: center;
    
            div.image {
                img {
                    display: inline-block;
                    margin: 0 auto;
                    padding: 0;
                    line-height: 1em;
                }
            }

            div.video {
                position: relative;
                padding-bottom: 56.25%; /* 16:9 */
                height: 0;
                margin: 4rem 0;
                
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .text-wrapper {
            .iconRow {
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .iconRow {
            display: flex;
            flex-direction: row-reverse;
            margin-bottom: 8rem;
            align-items: center;

            @include mobile {
                flex-direction: column;
            }

            &:hover {
                h3 {
                    @include with-moustache-in;
                }
            }

            h3 {
                margin-top: 0;
                padding-top: 2rem;
                font-size: 3rem;
                margin-bottom: 2rem;
                @include with-moustache-out;

                @include mobile {
                    font-size: 2.8rem;
                }
            }

            figure.icon {
                max-width: 10rem;
                margin: 1rem 0 0 3rem;

                @include legacy-down {
                    margin: 1rem 0 0 1.75rem;
                }                

                @include mobile {
                    margin: 0 0 0 auto;
                    max-width: 8rem;

                    img {
                        transform: translateY(30px);
                    }
                }

                @include tiny {
                    img {
                        transform: translateY(50px);
                    }
                }
            }

            &.reverse {
                flex-direction: row;

                @include mobile {
                    flex-direction: column;
                }

                figure.icon {
                    margin: 1rem 3rem 0 -3rem;

                    @include legacy-down {
                        margin: 1rem 3rem 0 -1.75rem;
                    }

                    @include tablet-down {
                        margin: 1rem 3rem 0 auto;
                    }

                    @include mobile {
                        margin: 0 0 0 auto;
                    }
                }
            }
        }

        blockquote {
            background: #fff;
            font-size: 2.2rem;
            line-height: 1.8em;
            font-weight: 300;
            margin: 6rem 6rem 6rem 0;
            padding: 3rem 6rem 3rem 3rem;
            border-left: 1rem solid var(--cyan);
            font-style: italic;
            font-family : var(--headline);
            opacity: 0.8;

            @include mobile {
                margin-right: 1.5rem;
                padding: 2rem;
                font-size:1.7rem;
                line-height: 1.5em;
            }
        }

        li {
            font-size: 2.0rem;
            line-height: 1.5em;
            margin-bottom: 1rem;
            font-weight: 300;

            @include phablet-down {
                font-size: 1.7rem;
            }
        }

        ol, ul {
            margin-top: 0;
            margin-bottom: 1.5em;
        }

        ul.columns {
            column-count: 2;
            margin-left: 0;
            padding-left: 1.1em;

            @include phablet-down {
                column-count: 1;
            }

            li {
                font-size: 1.8rem;

                @include legacy-down {
                    font-size: 1.7rem;
                }

                @include phablet-down {
                    font-size: 1.6rem;
                }
            }
        }
    }

    a.button {
        margin-top: 2.5rem;

        @include phablet-down {
            margin-top: 0;
        }
    }

    .moreButton {
        text-align: center;
        margin-top: 3rem;
    }
}

@import './blocks/text.scss';
@import './blocks/textTwo.scss';
@import './blocks/blogPosts.scss';
@import './blocks/textImage.scss';
@import './blocks/projects.scss';
@import './blocks/testimonial.scss';