/**
 * pages/home.pcss
 *
 * Styles for the home page
 *
 */


 .page--home {
    background: var(--background);
    position: relative;
    z-index: 5;
    min-height: 100vh;

    .c-block {
        .block-content {
            h3.h3Center {
                margin-bottom: 8rem;
            }
        }
    }
        

    .block-container {
        .c-block:first-of-type {
            padding-top: 10rem !important;

            &.background-darkBand {
                @include tablet-down {
                    padding-top: 8rem !important;
                    padding-bottom: 4rem !important;
                }

                @include mobile {
                    padding-top: 2.5rem !important;
                    padding-bottom: 4rem !important;
                }
            }
        }

        .c-block:last-of-type {
            @include phablet-down {
                padding-bottom: 10rem;
            }
        }
    }
}


/**
 * meet the team
 *
 */

 .c-meetTheTeam {
    position: relative;
    z-index: 10;
    padding-top: 10rem;
    background: #f1f1f1;
    max-width: 100%;
    overflow: hidden;

    .wrapper {
        display: flex;
        align-items: flex-end;

        @include phablet-down {
            flex-direction: column;
        }

        &.halfin {
            .text {
                h2 {
                    @include with-moustache-in;

                    &::after {
                        transition-delay: 0.5s;
                    }
                }
            }
        }

        .text {
            flex-grow: 1;
            padding: 0 0rem 4rem 0rem;
            min-width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            @include tablet-down {
                padding-bottom: 0;
            }

            h2 {
                margin-top: 0;
                @include with-moustache-out;
                &::after {
                    bottom: -1.6rem;
                }

                @include legacy {
                    font-size: 5rem;
                }

                @include tablet {
                    min-width: 600px;
                }

                @include phablet-down {
                    min-width: 280px;
                }

                @include mobile {
                    br  {
                        content: ' '
                    }
                    br:after {
                        content: ' '
                    }
                }
            }
            
            p {
                font-size: 2.2rem;
                padding-bottom: 1rem;
                max-width: 720px;

                @include mobile {
                    font-size: 1.7rem;
                }
            }

            .buttons {
                display: flex;
                align-items: center;

                a {
                    font-family: 'Fira Sans',sans-serif;
                    display: inline-block;
                    color: #fff;
                    font-weight: 500;
                    text-decoration: none;
                    font-size: 2.4rem;
                    padding: 1rem 2rem;
                    background: var(--orange);
                    transition: transform .3s ease-in-out;
                }

                .or {
                    padding: 0 3rem;
                }
            }
        }

        .team {
            position: sticky;
            bottom: 0;
            min-width: min( 600px, 50% );
            @include desktop-up {
                transform: translateX(8rem);
            }

            @include phablet-down {
                position: relative;
                min-width: 100%;
            }

            div.image {
                //overflow:hidden;
                padding-left: 2rem;
            }
            
            div.illustrationTeam {
                transform: translate3d(3000px, 0, 0) scaleX(3);
                animation-duration: 1.5s;
                animation-fill-mode: forwards;
            }

            // img {
            //     display: block;
            //     padding: 0;
            //     margin: 0;
            //     line-height: 1em;
            //     width: 100%;
            // }
        }

        &.active {
            .team {
                div.illustrationTeam {
                    animation-name: bounceInRight;
                    opacity: 1;
                }
            }
        }

        .buttons {

        }
    }
}
