/**
 * above-footer.css
 *
 */

.c-readyToSimplicate {
    position: relative;
    z-index: 10;
    padding-top: 8rem;
    background: #f1f1f1;
    max-width: 100%;
    overflow: hidden;

    @include phablet-down {
        padding-top: 12rem;
        position : relative;

        &::before {
            content : '';
            width: 120px;
            border-top: 4px dashed var(--dark);
            height: 1px;
            display: block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 6rem;
            opacity: 0.25;
        }
    }

    .container {
        @include desktop-up {
            max-width: calc( 100% - 16rem );
            width: $widescreen-width;
        }
    }

    .wrapper {
        display: flex;
        align-items: center;

        @include phablet-down {
            flex-direction: column;
        }

        .text {
            flex-grow: 1;
            padding: 0 0rem 4rem 0rem;
            min-width: 60%;

            @include phablet-down {
                max-width: 100%;
            }
            
            .title {
                font-family: var(--headline);
                color: var(--bunker);
                font-weight: normal;
                margin-bottom: 2rem;
                font-size: 4rem;
                font-weight: 900;
                line-height: 1.2em;

                @include tablet-up {
                    white-space: nowrap;
                }

                @include phablet-down {
                    font-size: 3rem;
                    text-align: center;
                }

                @include mobile {
                    text-align: center;
                }
            }

            p {
                font-size: 2.2rem;
                padding-bottom: 1rem;
                max-width: 720px;

                @include mobile {
                    text-align: center;
                }
            }

            .buttons {
                display: flex;
                align-items: center;

                @include phablet-down {
                    flex-direction: column;
                    justify-content: center;
                }

                a {
                    font-family: 'Fira Sans',sans-serif;
                    display: inline-block;
                    color: #fff;
                    font-weight: 500;
                    text-decoration: none;
                    font-size: 2.4rem;
                    padding: 1rem 2rem;
                    background: var(--orange);
                    transition: transform .3s ease-in-out;
                    border-radius: 0 1rem 1rem;

                    @include tablet {
                        font-size: 2.2rem;
                        padding: 1rem 1.5rem;
                    }

                    &:hover,
                    &:focus {
                        transform: rotate(-2deg) scale(1.05);
                    }
                }

                .or {
                    padding: 0 3rem;
                    position : relative;

                    @include tablet {
                        padding: 1.5rem;
                    }

                    @include phablet-down {
                        padding: 3rem 0;
                    }

                    &::before,
                    &::after {
                        content: ' - ';
                        opacity: 0.5;
                    }
                }
            }
        }

        .team {
            min-width: 40%;

            @include desktop-up {
                transform: translateX(8rem);
            }

            @include phablet-down {
                min-width: 100%;
            }

            @include tablet {
                align-self: flex-end;
            }

            div.illustrationTeam {
                transform: translate3d(3000px, 0, 0) scaleX(3);
                animation-duration: 1.5s;
                animation-fill-mode: forwards;
            }

            // img {
            //     transform: translate3d(3000px, 0, 0) scaleX(3);
            //     animation-duration: 1s;
            //     animation-fill-mode: forwards;
            //     animation-delay: 0.2s;
            //     display: block;
            //     padding: 0;
            //     margin: 0;
            //     line-height: 1em;
            //     width: 100%;
            // }
        }

        &.active {
            .team {
                div.illustrationTeam {
                    animation-name: bounceInRight;
                    opacity: 1;
                }
            }
        }

        .buttons {

        }
    }
}