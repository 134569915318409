/**
 * pages/blog.scss
 *
 * Styles for news posts
 *
 */

$blogArticleWidth: 740px;

.c-block--blogPosts,
.blog-related,
.page--blog-home {

    .articles {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        article {
            min-width: calc( 33.33% - 4rem );
            max-width: calc( 33.33% - 4rem );

            @include legacy {
                min-width: calc( 33.33% - 2rem );
                max-width: calc( 33.33% - 2rem );
            }

            @include tablet {
                min-width: calc( 33.33% - 2rem );
                max-width: calc( 33.33% - 2rem );
            }

            @include full-width-phablet-down;

            @include phablet {
                max-width: 340px !important;
                min-width: 340px !important;
                margin: 0 auto;
            }

            .text {
                
            }

            h3 {
                margin-bottom: 3rem;
            }
        }
        
        @include tablet-up {
            &::after {
                content: '';
                flex-grow: 1;
                min-width: calc( 33% - 4rem );
                max-width: calc( 33% - 4rem );
            }
        }

        @include phablet {
            &::after {
                display: none;
            }

        }
    }
}


.tease.blog {

    .meta {
        padding: 0 0 2.5rem 0.25rem;

        @include phablet-down {
            display: flex;
            flex-direction: column-reverse;
        }

        .author {
            margin-right: 1rem;
            display: inline-block;

            @include phablet-down {
                margin-top: 2rem;
            }

            &::after {
                content: '▪';
                padding-left: 1rem;

                @include phablet-down {
                    display: none;
                }
            }

            &.steve-comrie {
                position: relative;
                padding-left: 40px;
                &::before {
                    content: '';
                    left: 0;
                    top: -6px;
                    display: block;
                    position: absolute;
                    height: 30px;
                    width: 30px;
                    background-image: url(https://simplicate.nyc3.digitaloceanspaces.com/simplicate/assets/site/images/pages/about/_squareCrop/steve-bw.jpg);
                    background-size: cover;
                    background-repeat: no-repeat;
                    border-radius: 50%;
                }
            }
        }

        time {

        }
    }


    &.layout-featured {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 8rem;

        .wrapper {
            flex-direction: row-reverse;

            @include phablet-down {
                flex-direction: column;
            }
        }

        h2 {
            margin-top: 0;
            max-width: 70rem;

            a {
                color: var(--text);
                text-decoration: none;

                &:hover {
                    color: var(--orange);
                }
            }
        }


        .text {
            p {
                font-size: 2rem;
            }
        }

        .image {
            min-width: 40%;
            max-width: 40%;
            margin: 0 0 0 6rem;
            text-align: right;
            
            @include desktop-up {
                margin-top: -8rem;
            }

            @include full-width-mobile;

            @include phablet-down {
                max-width: 100%;
                margin-left: 0rem;
                margin-bottom: 4rem;
            }

            img {
                border-radius: 0 3rem 3rem 3rem;
                
                @include desktop-up {
                    min-width: calc( 100% + 5rem);
                    max-width: calc( 100% + 5rem);
                }

                @include desktop {
                    //transform : translateX( min(calc(( 100vw - 1200px) / 2 ), 6rem ) );
                }

                @include phablet-down {
                    transform: translateX(0);
                }
                
            }
        }
    }

    &.layout-normal {
        background: var(--blue);
        position: relative;
        color: #fff;
        border-radius: 0 2rem 2rem 2rem;
        transition: background-color 0.3s ease;
        margin-bottom: 7rem;

        @include legacy {
            margin-bottom: 3.5rem;
        }

        @include tablet {
            margin-bottom: 3.5rem;
        }

        @include mobile {
            margin-bottom: 3.5rem;
        }

        // &:last-of-type {
        //     @include phablet-down {
        //         margin-bottom: 0rem;
        //     }            
        // }

        &:hover {
            background: var(--bunker);

            a {
                color: var(--orange);

                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512.008 512.008'%3E%3Cpath fill='%23ff5800' d='M508.885 248.468L306.219 45.801c-21.803-21.803-57.28-21.803-79.083 0s-21.803 57.28 0 79.083l77.781 77.781H53.333C23.936 202.665 0 226.601 0 255.999s23.936 53.333 53.333 53.333h251.584l-77.781 77.781c-21.803 21.803-21.803 57.28 0 79.083 10.901 10.901 25.216 16.363 39.552 16.363s28.629-5.44 39.552-16.363l202.667-202.667c4.138-4.16 4.138-10.901-.022-15.061z'/%3E%3C/svg%3E");
                }
            }
        }

        .inner {
            padding: 4rem;
            min-height: 30rem;
            display : flex;
            flex-direction: column;
            justify-content: space-between;

            @include legacy {
                padding: 3rem;
            }

            @include tablet {
                padding: 2.5rem;
            }

            @include phablet-down {
                min-height: 10rem;
                padding: 3rem;
            }
        }

        h2, h3 {
            font-size: 3.5rem;
            margin-top: 0;
            flex-grow : 1;

            @include tablet-down {
                font-size: 3rem;
            }
        }

        p {
            font-size: 1.8rem;
        }

        .text {

        }

        time {
            display: block;
            margin-bottom: 2rem;
        }

        a {
            color: #fff;
            font-weight: bold;
            padding-right: 24px;
            text-decoration: none;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            &::after {
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                position: relative;
                left: 8px;
                top: 4px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512.008 512.008'%3E%3Cpath fill='%23fff' d='M508.885 248.468L306.219 45.801c-21.803-21.803-57.28-21.803-79.083 0s-21.803 57.28 0 79.083l77.781 77.781H53.333C23.936 202.665 0 226.601 0 255.999s23.936 53.333 53.333 53.333h251.584l-77.781 77.781c-21.803 21.803-21.803 57.28 0 79.083 10.901 10.901 25.216 16.363 39.552 16.363s28.629-5.44 39.552-16.363l202.667-202.667c4.138-4.16 4.138-10.901-.022-15.061z'/%3E%3C/svg%3E");
            }
        }
    }

    min-width: calc( 33.33% - 4rem );
    max-width: calc( 33.33% - 4rem );
    margin-bottom: 5rem;
    
    @include full-width-mobile;

    .wrapper {
        display: flex;
        flex-direction: column;
    }
}

.blog-body {
    .c-toc {
        max-width: 740px;
        margin: 0 auto;
    }
}


.blog-content {

    margin-top: 15rem;

    @include mobile {
        margin-top: 10rem;
    }

    .container { 
        max-width: 740px;
        transform: translateX(100px);

        @include legacy {
            max-width: 640px;
        }

        @include tablet-down {
            transform: translateX(0px);
        }
    }

    .blog-body {
        position: relative;
    }

    .block-container {
        .c-block {
            .block-content {

                .header-wrapper {
                    &.halfin {
                        h2 {
                            @include with-moustache-in;
                        }
                    }

                    h2 {
                        @include with-moustache-out;
                    }
                }

                h2 {
                    font-size: 4rem;
                    margin : 6rem 0 5rem 0;
                    text-align: left !important;

                    @include phablet-down {
                        font-size: 3rem;
                    }
                }

                h3 {
                    font-size: 3rem;

                    @include phablet-down {
                        font-size: 2.5rem;
                    }
                }

                h4 {
                    margin-top: 6rem;
                    margin-bottom : 1.5rem;
                    font-family: var(--text);
                    font-weight: bold;
                    font-size: 2.1rem;
                }

                p + h3 {
                    margin-top: 10rem;
                }

                a {
                    color: var(--bunker);
                    
                    &:focus,
                    &:hover {
                        color: var(--orange);
                    }
                }

                figure {
                    margin-top : 8rem;
                    margin-bottom: 8rem;

                    div.img {
                        margin-right: -2rem;
                        margin-left: -2rem;

                        @include tablet-down {
                            margin-right: 0rem;
                            margin-left: 0rem;
                        }
                    }

                    figcaption {
                        font-weight: 300;
                        font-size: 1.6rem;
                        margin-top: 1rem;
                        text-align: center;
                        font-style: normal;
                        line-height: 1.6em;

                        @include mobile {
                            font-size: 1.3rem;
                        }
                    }
                }
            }
        } 
    }

    .title-meta {

        @include legacy-up {
            //transform: translateX(-5rem);
        }

        @include tablet-down {
            transform: translateX(0rem);
        }

        nav.breadcrumb {
            padding: 0 0 0 0.5rem;

            li:last-of-type {
                a {
                    &::after {
                        display: none;
                    }
                }
            }

            a {
                text-decoration : none;
                color: var(--dark);
                
                &:hover {
                    color: var(--orange);

                    &::after {
                        color: var(--dark);
                    }
                }
                
                &::after {
                    content: '/';
                    font-size: 1.5rem;
                    padding: 0 1rem;
                }
            }
        }

        .meta {
            padding: 0 0 2.5rem 0.25rem;

            @include phablet-down {
                display: flex;
                flex-direction: column;
            }

            .author {
                margin-right: 1rem;
                display: inline-block;

                @include phablet-down {
                    margin-bottom: 2rem;
                }

                &::after {
                    content: '▪';
                    padding-left: 1rem;

                    @include phablet-down {
                        display: none;
                    }
                }

                &.steve-comrie {
                    position: relative;
                    padding-left: 40px;
                    &::before {
                        content: '';
                        left: 0;
                        top: -6px;
                        display: block;
                        position: absolute;
                        height: 30px;
                        width: 30px;
                        background-image: url(https://simplicate.nyc3.digitaloceanspaces.com/simplicate/assets/site/images/pages/about/_squareCrop/steve-bw.jpg);
                        background-size: cover;
                        background-repeat: no-repeat;
                        border-radius: 50%;
                    }
                }
            }

            time {

            }
        }
    }

    h1 {
        font-size: 7rem;
        margin-top: 2rem;

        @include phablet-down {
            font-size: 5rem;
            margin-top: 4rem;
            margin-bottom: 4rem;
        }

        @include mobile {
            font-size: 4rem;
        }
    }


    .image-wrapper {
        text-align: right;
    }

    .image {
        margin-bottom: 6rem;
        text-align: center;

        img {
            max-width: 1000px;
            width: 100%;
            border-radius: 0 3rem 3rem;
            margin: 0 0 0 auto;

            @include desktop-up {
                transform: translateX(8rem);
            }

            @include widescreen {
                max-width: 1200px;
                transform: translateX(16rem);
            }

            @include legacy-down {
                max-width: min( 1000px, calc( 100% - 2rem ) );
            }
        }
    }
}

.blog-related {
    background: #fff;
    padding: 10rem 0;

    h2 {
        text-align: center;
        margin-top: 0;
        margin-bottom: 6rem;
    }

    .moreButton {
        text-align: center;
        margin-top: 3rem;
    }
}