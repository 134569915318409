#textTwo-566,
#textTwo-567,
#textTwo-244 {
    .text {
        @include phablet-down {
            margin-bottom: 5rem;

            &.left {
                margin-bottom: 10rem;
            }
        }
    }
}

.c-block--textTwo {
    .wrapper {
        display: flex;
        justify-content: space-between;

        @include phablet-down {
            flex-direction: column;
        }

        .text {
            min-width: calc( 50% - 4rem );
            max-width: calc( 50% - 4rem );
            position: relative;
            z-index: 3;

            @include legacy-down {
                min-width: calc( 50% - 2rem );
                max-width: calc( 50% - 2rem );
            }

            @include full-width-phablet-down;

            h2:first-child, h3:first-child {
                margin-top: -0.5rem !important;
            }

            img {
                min-width: 100%;
            }

            &.left {
                @include phablet-down {
                    margin-bottom: 3rem;
                }

                .text-wrapper {
                    h2, h3 {
                      &:last-child {
                          margin-bottom: 0;
                      }  
                    }
                }
            }

            .text-wrapper {
                figure:first-child {
                    margin-top : 0;
                }
            }
        }   
    }

    &.background-normal {
        .text.left {
            h2, h3 {
                text-shadow: 2px 2px 0px #fff;
            }
        }
    }

    &.layout-weightedRight {
        .wrapper {
            .text.left {
                min-width: calc( 37% - 4rem );
                max-width: calc( 37% - 4rem );

                @include legacy-down {
                    min-width: calc( 37% - 2rem );
                    max-width: calc( 37% - 2rem );
                }

                @include full-width-phablet-down;

                h2 {
                    font-size: 5rem;

                    @include legacy-down {
                        font-size: 4.5rem;
                    }

                    @include tablet {
                        font-size: 4rem;
                    }

                    @include phablet-down {
                        font-size: 3.5rem;
                    }
                }

                p.small {
                    margin-top: -5rem;

                    @include phablet-down {
                        margin-top: 0;
                    }

                    a {
                        color: var(--orange);
                        font-weight: 500;
                        text-decoration: none;

                        &:hover,
                        &:focus {
                            text-decoration: underline;
                        }
                    }
                }

                h2 + p.small,
                h3 + p.small {
                    margin-top: 0;
                }
            }

            .text.right {
                min-width: calc( 63% - 4rem );
                max-width: calc( 63% - 4rem );

                @include legacy-down {
                    min-width: calc( 63% - 2rem );
                    max-width: calc( 63% - 2rem );
                }

                @include full-width-phablet-down;

                p {
                    @include legacy-down {
                        max-width: 640px;
                    }
                }
            }
        }
    }
}