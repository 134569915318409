#page-footer {
    background: var(--dark);
    color: #fff;
    position: relative;
    z-index: 10;
    font-size: 1.4rem;

    @include phablet-down {
        font-size: 1.4rem;
    }

    .container {
        @include desktop-up {
            max-width: calc( 100% - 16rem );
            width: $widescreen-width;
        }
    }

    .wrapper {
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include tablet-down {
            flex-direction: column-reverse;
            text-align: center;
        }

        div.legal, nav {
            padding: 1rem 0;
            font-weight: 300;

            @include legacy {
                font-size: 1.6rem;

            }

            @include tablet-down {
                line-height: 1.8em;
            }
        }

        a {
            color: #fff;
            text-decoration: none;
            font-weight: 300;

            &:focus,
            &:hover {
                color: var(--orange);
            }
        }
    }

    nav {
        li {
            margin-left: 2.5rem;

            @include tablet-down {
                margin-left: 0rem;
                padding: 1rem;
            }

            &:first-of-type {
                margin-left: 0;
            }
        }
    }
    
}