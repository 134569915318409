/**
 * components/global.css
 *
 * Project-wide variables & mixins
 *
 */


 :root {
  --dark: #414141;
  --lessdark: #676767;
  --grey: #E6E7E8;
  --background: #f1f1f1;
  --blonde: #FFFFB3;
  --bunker: #162426;
  --beige: #E2E3DD;
  --darkbeige: #d2d3ca;
  --orange: #ff5800;
  --red: #F21D44;
  //--blue:#4D9EB3;
  //--blue:#16A1C0;
  --blue:#1DB2D3;
  //--blue:#4dc1ff;
  --cyan: #b4c9be;
  //--cyan: #acccbf;
  --blueRGB: 77, 193, 255;
  --green:#00ff58;
  --headline: 'Fira Sans', arial, sans-serif;
  --headlineWeight: 900;
  --text: 'Montserrat', times, serif;
  --ease: cubic-bezier(0.16, 1, 0.3, 1);
}


@function color($color-name) {
  @return var(--#{$color-name});
}



// Breakpoints
// -------------------------
$widescreen-width  : 1600px;
$desktop-width     : 1280px;
$legacy-width      : 1020px;
$tablet-width      : 760px;
$mobile-width      : 576px;
$small-mobile-width: 360px;

@mixin ultrawide {
  @media screen and (min-width: 1921px) {
      @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: #{$widescreen-width}) {
      @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop-width}) and (max-width: #{$widescreen-width - 1px}) {
      @content;
  }
}

@mixin desktop-up {
  @media screen and (min-width: #{$desktop-width}) {
      @content;
  }
}

@mixin legacy {
  @media screen and (min-width: #{$legacy-width}) and (max-width: #{$desktop-width - 1px }) {
      @content;
  }
}

@mixin legacy-up {
  @media screen and (min-width: #{$legacy-width}) {
      @content;
  }
}

@mixin legacy-down {
  @media screen and (max-width: #{$desktop-width - 1px}) {
      @content;
  }
}

@mixin tablet-up {
  @media screen and (min-width: #{$mobile-width + 1px}) {
      @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{$mobile-width + 1px}) and (max-width: #{$legacy-width - 1px}) {
      @content;
  }
}

@mixin tablet-down {
  @media screen and (max-width: #{$legacy-width - 1px}) {
      @content;
  }
}

@mixin phablet {
  @media screen and (min-width: #{$mobile-width + 1px}) and (max-width: #{$tablet-width}) {
      @content;
  }
}

@mixin phablet-down {
  @media screen and (max-width: #{$tablet-width - 1px}) {
      @content;
  }
}

@mixin mobile {
  @media screen and (max-width: #{$mobile-width}) {
      @content;
  }
}

@mixin tiny {
  @media screen and (max-width: #{$small-mobile-width}) {
      @content;
  }
}

@mixin laptop {
  @media screen 
    and (min-width: 1200px) 
    and (max-width: 1600px)
    and (max-height: 800px) {
      @content;
    }
}

@mixin shortscreen {
  @media screen 
    and (min-width: #{$tablet-width}) 
    and (max-height: 620px) {
      @content;
    }
}

.blockMeta {
  display: flex;
  justify-content: center;
  align-items: center;
  
  .blockMetaWrapper {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background: rgba(255,255,255,0.75);
      border: 2px dotted #e12d39;
      width: 100%;
      max-width: 740px;
      margin-top: 5rem;

      div {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        font-size: 14px;
        text-align: center;
        color: #000 !important;

        &.type {
          font-size: 28px;
          justify-self: self-start;
        }

        strong {
          font-weight: bold;
          font-size: 18px;
        }

        span {
          
        }

        a {
          color: #e12d39;
        }
      }
  }
}





























































// variables
$dot-size: 5px;
$dot-border-radius: $dot-size / 2;
$dot-color: var(--dark);

div.c-moreMenu {
    &.up {
        .toggle.active {
            background: #fff;
            border: 1px solid #999 !important;
            border-top: 0 !important;
            border-radius: 0px 0px 6px 6px;
        }

        ul.more {
            top: auto;
            bottom: 39px;
            border-radius: 0px 6px 0px 6px;
        }
    }

    &.home {
        .toggle {
            &:hover {
                background: rgba(0,0,0,0.1);
            }
        }
    }

    .toggle {
        width: 40px;
        height: 40px;
        background: transparent;
        position: relative;
        z-index: 25;
        border-radius: 0px 6px 0px 0px;

        &:hover {
            background: rgba(255,255,255,0.5);

            body.scrolled-200 & {
                background: rgba(0,0,0,0.1);
            }
        }

        &:active {
            box-shadow: none;
        }
        
        .nav-dots {
            position: absolute;
            left: 17px;
            top: 17px;
            display: block;
            width: $dot-size;
            height: $dot-size;
            border-radius: $dot-border-radius;
            background-color: $dot-color;
            transition: all .1s ease-in-out;

            &::before,
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                content:"";
                width: $dot-size;
                height: $dot-size;
                border-radius: $dot-border-radius;
                background-color: $dot-color;
                transition: all .1s ease-in-out;
            }
            
            &::before {
                transform:translateY(-8px);
            }
            
            &::after {
                transform:translateY(8px);
            }
            
        }
        
        &.active {
            background: #fff !important;
            border: 1px solid #999;
            border-bottom: 0;
            
            .nav-dots {
                background-color: transparent;
                
                &::before,
                &::after {
                    left: -7px;
                    top: 1px;
                    height: 3px;
                    width: 18px;
                }
                
                &::before {
                    transform:rotate(45deg);
                }
                
                &::after {
                    transform:rotate(-45deg);
                }   
            }
        }
    }

    ul.more {
        position: absolute;
        border: 1px solid #999;
        top: 39px;
        right: 0rem;
        display: flex;
        flex-direction: column;
        background: #fff;
        z-index: 20;
        align-items: flex-end;
        -webkit-box-shadow: 3px 3px 12px 1px rgba(0,0,0,0.6); 
        box-shadow: 3px 3px 12px 1px rgba(0,0,0,0.6);
        padding: 0.5rem 0;
        border-radius: 6px 0px 6px 6px;

        li {
            display: block;
            margin: 0;

            a {
                display: flex;
                height: auto;
                font-size: 2rem;
                padding: 1rem 1.1rem 1rem 1.5rem;
                justify-content: flex-end;
                text-align: right;

                &:hover {
                    color: var(--orange);
                }
            }
        }
    }
}
