/**
 * pages/search.scss
 *
 * Styles for search results
 *
 */


.page-search {

    article.tease {
        margin-bottom: 10rem;
        position: relative;
        padding-top: 10rem;
   
        &:first-of-type {
           padding-top: 5rem;
        }
   
        &:hover {
           h3 {
               a {
                   color: var(--orange);
               }
           }
   
        }
   
        h3 {
           margin: 0 0 2rem 0;
   
            a {
                text-decoration : none;
                color: var(--text);
   
                &::after {
                    content: '';
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    display: block;
                    position: absolute;
                }
            }
        }

        .text {
            p {
                &::after {
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    left: 8px;
                    top: 4px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512.008 512.008'%3E%3Cpath fill='%23ff5800' d='M508.885 248.468L306.219 45.801c-21.803-21.803-57.28-21.803-79.083 0s-21.803 57.28 0 79.083l77.781 77.781H53.333C23.936 202.665 0 226.601 0 255.999s23.936 53.333 53.333 53.333h251.584l-77.781 77.781c-21.803 21.803-21.803 57.28 0 79.083 10.901 10.901 25.216 16.363 39.552 16.363s28.629-5.44 39.552-16.363l202.667-202.667c4.138-4.16 4.138-10.901-.022-15.061z'/%3E%3C/svg%3E");
                }
            }
        }
   
       .image.blog {
           img {
               border-radius: 0 2rem 2rem 2rem;
           }
       }
   
       &:not(:first-of-type)::before {
           content: "";
           width: 120px;
           border-top: 4px dashed var(--dark);
           height: 1px;
           display: block;
           position: absolute;
           left: 50%;
           transform: translateX(-50%);
           top: 0rem;
           opacity: 0.25;
       }
    }

    .suggest {
        margin-top: 6rem;

        h3 {
            text-align: center !important;
        }

        ul {
            padding-left: 0 !important;
                column-count: 2;
            margin-left: 0;
            padding-left: 1.1em;

            @include phablet-down {
                column-count: 1;
            }

            li {
                font-size: 1.8rem;
                list-style: none;
                margin-bottom: 1.25rem !important;

                @include legacy-down {
                    font-size: 1.7rem;
                }

                @include phablet-down {
                    font-size: 1.6rem;
                }
    
                a {
                    color: var(--bunker);
                    font-weight: bold;
                    text-decoration: none !important;
                    display: inline-block;
                    padding: 0.3rem 0.8rem;
    
    
                    color: var(--text);
                    background: #dbe9e4;
                    text-decoration-line: underline;
                    text-decoration-style: solid;
                    text-decoration-thickness: 0.05em;
                    text-decoration-skip: edges;
            
                    &:hover, &:focus {
                        background: #7daf9a;
                        color: #fff;
                        text-decoration: none;
                    }
    
    
                }
            }
        }
    }

}
