/**
 * components/utility.css
 * 
 * Utility classes
 *
 */

@mixin reset-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
  
@mixin horizontal-list {
    @include reset-list;
  
    li {
        display: inline-block;
        margin: 0;
        padding: 0;
    }
}

@mixin no-mobile-brs {
  @include phablet-down {
    br  {
      content: ' '
    }

    br:after {
        content: ' '
    }
  }
}


nav ul, nav ol {
    @include horizontal-list;
}

@mixin full-width-mobile {
  @include mobile {
    min-width: 100%;
    max-width: 100%;
  }
}

@mixin full-width-phablet-down {
  @include phablet-down {
    min-width: 100%;
    max-width: 100%;
  }
}

@mixin full-width-tablet {
  @include tablet {
    min-width: 100%;
    max-width: 100%;
  }
}

@mixin full-width-tablet-down {
  @include tablet-down {
    min-width: 100%;
    max-width: 100%;
  }
}


@mixin with-moustache-in {
  &::after {
    width: 1.8em;
    transition-delay: 0s;
  }
}

@mixin with-moustache-out {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    bottom: -1rem;
    left: 0;
    height: 0.15em;
    width: 0em;
    background: var(--cyan);
    transition: width 0.3s;
    transition-delay: 0.5s;
  }
}

@mixin with-moustache {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    bottom: -1.6rem;
    left: 0;
    height: 0.15em;
    width: 1.8em;
    background: var(--cyan);
  }
}

.tag {
    display: inline-block;
    padding: .2rem .5rem;
    background: #eee;
    margin: .2rem 1rem .2rem -.5rem;
}


.scrollTop {
    scroll-margin-top: 10rem;
}

.relative {
    position: relative;
}

.sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
}


@keyframes bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    from {
      opacity: 0;
      transform: translate3d(0, 3000px, 0) scaleY(5);
    }
  
    60% {
      opacity: 1;
      transform: translate3d(0, -20px, 0) scaleY(0.9);
    }
  
    75% {
      transform: translate3d(0, 10px, 0) scaleY(0.95);
    }
  
    90% {
      transform: translate3d(0, -5px, 0) scaleY(0.985);
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
}


@keyframes bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    from {
      opacity: 0;
      transform: translate3d(3000px, 0, 0) scaleX(3);
    }
  
    60% {
      opacity: 1;
      transform: translate3d(-25px, 0, 0) scaleX(1);
    }
  
    75% {
      transform: translate3d(10px, 0, 0) scaleX(0.98);
    }
  
    90% {
      transform: translate3d(-5px, 0, 0) scaleX(0.995);
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  