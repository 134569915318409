/**
 * components/typography.css
 * 
 * Typography rules.
 *
 */

/**
 !! ADD GOOGLE FONT IMPORTS TO:
 !! templates/_inline_css/site-fonts.css
 **/

h1, h2, h3, h4 {
    font-family: var(--headline);
    font-weight: normal;
    scroll-margin-top: 12rem;
    font-weight: var(--headlineWeight);
    font-variant-ligatures: none;
}


h1 {
    font-size: 10rem;

    @include phablet-down {
        font-size: 5rem;
    }

    @include mobile {
        font-size: 4.5rem;
    }
}

h2 {
    font-size: 5.5rem;

    @include laptop {
        font-size: 5rem;
    }

    @include tablet {
        font-size: 4rem;
    }

    @include phablet-down {
        font-size: 3.5rem;
    }

    .eyebrow-wrapper {
        display: block;
    }

    .eyebrow {
        font-size: 1.8rem;
        line-height: 1em;
        text-transform:uppercase;
        display: inline-block;
        margin : 0 auto;
        text-align: center;
        padding: 1rem;
        font-weight: 500;
        margin-bottom: 1rem;
        transform: rotate(2deg);
        transition: transform 0.8s ease-out 0.8s;
        position: relative;
        overflow: hidden;
        color: #fff;

        @include phablet-down {
            margin-bottom: 0;
        }

        &::before {
            position: absolute;
            content: '';
            top: -40px;
            left: -40px;
            height: 30px;
            width: 30px;
            background: #fff;
            transform: scale(13) rotate(90deg);
            transition: transform 0.8s ease-out 0.5s;
            z-index: 5;
        }

        &::after {
            position: absolute;
            content: '';
            top: 1px;
            left: 1px;
            height: calc( 100% - 2px );
            width: calc( 100% - 2px );
            background: var(--blue);
            z-index: -1;
        }
    }
}

h3 {
    font-size: 4rem;

    @include laptop {
        font-size: 3.5rem;
    }

    @include tablet {
        font-size: 3rem;
    }

    @include phablet-down {
        font-size: 2.5rem;
    }

    &.hasEyebrow {
        @include phablet-down {
            font-size: 3.5rem;
        }        
    }
}

h4 {
    font-size: 2.5rem;

    @include phablet-down {
        font-size: 2rem;
    }
}

p {
    font-size: 2.0rem;
    line-height: 1.6em;
    margin-top: 0;
    font-weight: 300;
    margin-bottom: 1.5em;

    @include phablet-down {
        font-size: 1.7rem;
    }

    &.lead {
        font-size: 2.5rem;
        font-weight: 300;

        @include tablet {
            font-size: 2rem;
        }

        @include phablet-down {
            font-size: 2rem;
        }
    }

    &.small {
        font-size: 1.5rem;
        font-weight: 300;

        @include phablet-down {
            font-size: 1.4rem;
        }
    }

    &.seeAlso {
        display: block;
        font-size: 2rem;
        padding: 1rem;
        background: var(--beige);
        margin: 0rem -1rem;
        border: 2px dashed var(--darkbeige);

        @include phablet-down {
            font-size: 1.7rem;
        }

        @include mobile {
            margin: 0rem 0rem;            
        }

        a {
            color: var(--dark);

            &:focus,
            &:hover {
                color: var(--orange) !important;
            }
        }
    }
}

a.callout {
    color: var(--orange);
    font-weight: bold;
    position: relative;
    padding: 0 0.5rem;
    margin: 0 0.5rem 1.5rem;
    display: table;

    text-decoration: none;
    background-image: linear-gradient(120deg, #ff5800, #ff5800);
    background-size: 100% 2px;
    background-position: 0 95%;
    background-repeat: no-repeat;
    transition: background-size 0.15s linear 0.25s, color 0.15s linear 0.25s;

    &:hover, &:focus {
        background-size: 100% 100%;
        color: #fff;

        &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512.008 512.008'%3E%3Cpath fill='%23ff5800' d='M508.885 248.468L306.219 45.801c-21.803-21.803-57.28-21.803-79.083 0s-21.803 57.28 0 79.083l77.781 77.781H53.333C23.936 202.665 0 226.601 0 255.999s23.936 53.333 53.333 53.333h251.584l-77.781 77.781c-21.803 21.803-21.803 57.28 0 79.083 10.901 10.901 25.216 16.363 39.552 16.363s28.629-5.44 39.552-16.363l202.667-202.667c4.138-4.16 4.138-10.901-.022-15.061z'/%3E%3C/svg%3E");
            opacity: 1;
        }
    }


    &::after {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        position: absolute;
        right: -2.25rem;
        top: 11px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512.008 512.008'%3E%3Cpath fill='%23676767' d='M508.885 248.468L306.219 45.801c-21.803-21.803-57.28-21.803-79.083 0s-21.803 57.28 0 79.083l77.781 77.781H53.333C23.936 202.665 0 226.601 0 255.999s23.936 53.333 53.333 53.333h251.584l-77.781 77.781c-21.803 21.803-21.803 57.28 0 79.083 10.901 10.901 25.216 16.363 39.552 16.363s28.629-5.44 39.552-16.363l202.667-202.667c4.138-4.16 4.138-10.901-.022-15.061z'/%3E%3C/svg%3E");
        transition: background-image 0.15s linear 0.25s,  opacity 0.15s linear 0.25s;
        opacity: 0.5;
    }
}

.background-colourBand {
    a.callout {
        background-image: linear-gradient(120deg, #7daf9a, #7daf9a);
        color: var(--bunker);

        &:hover, &:focus {
            color: #fff;

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512.008 512.008'%3E%3Cpath fill='%237daf9a' d='M508.885 248.468L306.219 45.801c-21.803-21.803-57.28-21.803-79.083 0s-21.803 57.28 0 79.083l77.781 77.781H53.333C23.936 202.665 0 226.601 0 255.999s23.936 53.333 53.333 53.333h251.584l-77.781 77.781c-21.803 21.803-21.803 57.28 0 79.083 10.901 10.901 25.216 16.363 39.552 16.363s28.629-5.44 39.552-16.363l202.667-202.667c4.138-4.16 4.138-10.901-.022-15.061z'/%3E%3C/svg%3E");
            }
        }
    }
}

mark {
    background-color: var(--blonde);
}


/* letter animations */
.ml6 {
    position: relative;


    .text-wrapper {
        position: relative;
        display: inline-block;
        padding-top: 0.2em;
        padding-right: 0.05em;
        padding-bottom: 0.1em;
        overflow: hidden;
    }

    .letters {
        opacity: 0;
        white-space: nowrap !important;
    }

    .letter {
        display: inline-block;
        line-height: 1em;
    }
}
  


.ml10 {
    position: relative;
    font-weight: 900;
    font-size: 4em;

    @include phablet-down {
        font-size: 2.5rem;
    }

    .text-wrapper {
        position: relative;
        display: inline-block;
        padding-top: 0.2em;
        padding-right: 0.05em;
        padding-bottom: 0.1em;
        overflow: hidden;
    }
  
    .letter {
        display: inline-block;
        line-height: 1em;
        transform-origin: 0 0;
    }
}


.ml11 {
  
    .ml11 .text-wrapper {
        position: relative;
        display: inline-block;
        padding-top: 0.1em;
        padding-right: 0.05em;
        padding-bottom: 0.15em;
    }
  
    .letters {
        opacity: 0;
        white-space: nowrap !important;
    }

    .letter {
        display: inline-block;
        line-height: 1em;
    }
}



.header-wrapper {

    h2.hasEyebrow {
        opacity: 0;
        transform: translateY(-50px);
        transition: transform 0.3s ease-out 0.5s, opacity 0.3s ease-out 0.5s;
    }

    &.active {
        h2.hasEyebrow {
            opacity: 1;
            transform: translateY(0);

            strong.eyebrow {
                transform: rotate(-2deg);

                &::before {
                    transform: scale(0) rotate(20deg);
                }
            }
        }
    }
}

span.caps + span.amp {
    padding-left: 5px;
}