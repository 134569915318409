// fall - $skyColour: #fed2ac;
$skyColour: #e4edee;
$bushColour: #a2ab6f;

.c-hero {
    background: $skyColour;
    height    : 100%;
    overflow  : hidden;
    position  : relative;
    margin-bottom: 10rem;
    width     : 100%;
    height    : 100vh;

    @include phablet-down {
        margin-bottom: 4rem;
    }

    .text {
        display: flex;
        justify-content : center;
        align-items: center;
        flex-direction: column;
        color: var(--bunker);
        position: relative;
        z-index: 5;
        text-align: center;
        height: 100%;
        opacity: 1;
    }

    .image {
        position: absolute;
        top: 0;
        left: 0%;
        height: 100%;
        width: 100%;
        height : 400px;

        @include widescreen {
            height : 550px;
        }

        @include phablet-down {
            height : 280px;
        }

        img {
            object-fit: cover;
            object-position: 75% 75%;
            height: 100%;
            width: 100%;
            max-width: inherit;

            @include phablet-down {

            }
        }
    }

    &.normal {
        max-height: 400px;
        min-height: 400px;

        @include widescreen {
            max-height: 550px;
            min-height: 550px;
        }

        @include phablet-down {
            max-height: 280px;
            min-height: 280px;
        }

        .text {
            justify-content: flex-end;

            h1 {
                color: var(--bunker);
                // fall - text-shadow: 2px 2px 0px #a2ab6f;
                // text-shadow: 2px 2px 0px $bushColour;
                text-shadow: 2px 2px 0px #fff;
                text-align: center;
                padding-bottom: 4rem;
                max-width: 65%;
                margin: 0 auto;

                @include widescreen {
                    padding-bottom: 5rem;
                }
                                
                @include tablet-down {
                    text-align: left;
                    max-width: 70%;
                    margin: 0;
                }

                &.very-long {
                    font-size: 8rem;
                    line-height: 0.9em;
                    padding-bottom: 6rem;

                    @include desktop-up {
                        max-width: 65%;
                    }

                    @include phablet-down {
                        font-size: 4rem;
                        padding-bottom: 3rem;
                    }

                }

                &.long {
                    font-size: 8rem;
                    line-height: 1em;
                    padding-bottom: 5.5rem;

                    @include widescreen {
                        font-size: 9rem;
                        padding-bottom: 8.5rem;
                    }

                    @include phablet-down {
                        font-size: 4rem;
                        padding-bottom: 2rem;
                    }
                }

            }
        }
    }

    &.large {
        height    : calc( 100vh - 94px );
        min-height: 300px;
        max-height: 900px;
        margin-bottom: 0;
        position: relative;
        overflow: hidden;
        max-width: 1920px;

        @include phablet-down {
            max-height: 700px;
        }

        @include tiny {
            max-height: 600px;
        }

        .image {
            position: fixed;
            top: 0;
            left: 50%;
            height: calc( 100vh - 40px );
            max-height: 900px;
            width: 100%;
            max-width: 1920px;
            transform: translateX(-50%);
    
            @include phablet-down {
                max-height: 700px;
            }

            @include tiny {
                max-height: 600px;
            }

            img {
                object-fit: cover;
                object-position: 50% 100%;
                height: calc( 100vh - 40px );
                max-height: 900px;
                width: 100%;
                max-width: inherit;

                @include phablet {
                    max-height: 700px;
                    // height: calc( 100vh - 200px );
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }

                @include mobile {
                    // height: calc( 100vh - 400px );
                    max-height: 600px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
        }

        .text {
            position: fixed;
            top: 7rem;
            left: 0;
            width: 100%;
            text-align: center;
            align-items: flex-start;
            height: auto;

            @include laptop {
                top: 5rem;
            }

            @include tablet {

            }

            @include shortscreen {
                top: 5rem;
            }

            h1 {
                font-size: 7rem;
                max-width: 840px;
                margin: 0 auto;
                position: sticky;
                padding: 0 0 3rem;
                /* text-shadow: 2px 2px 0px #fff; */

                @include laptop {
                    font-size: 6rem;
                }

                @include tablet {
                    font-size: 6rem;
                }

                @include phablet-down {
                    font-size: 4rem;
                }

                @include mobile {
                    font-size: 3rem;
                }

                @include tiny {
                    font-size: 2.5rem;
                }                

                @include shortscreen {
                    font-size: 3rem;
                    padding-bottom: 1.5rem;
                }
                
            }

            .secondary {
                width: 100%;
                opacity: 0;
                transform:translateY(50px);
                animation-name: heroFadeInUp;
                animation-duration: 0.75s;
                animation-fill-mode: forwards;
                animation-delay: 1.25s;
            }

            div.title {
                display: block;
                text-align: center;
                margin : 0 auto;
                font-weight: normal;
                font-family: var(--headline);
                font-weight: 400;
                max-width: 940px;
                line-height: 1.45em;
                color: var(--dark);
                font-size: 4rem;

                div {
                    opacity: 0;
                    transform:translateY(25px);
                    animation-name: heroFadeInUp;
                    animation-duration: 0.5s;
                    animation-fill-mode: forwards;
                    animation-delay: 1.25s;

                    &:nth-of-type(2) {
                        animation-delay: 1.75s;
                    }
                }

                @include mobile {
                    font-size: 2rem;
                }

                @include tiny {
                    font-size: 1.6rem;
                }

                // @include laptop {
                //     font-size: 2.75rem;
                // }

                // @include phablet-down {
                //     font-size: 3.25rem;
                // }

                @include shortscreen {
                    font-size: 2.2rem;
                }
            }
        }
    }

    div.button {
        text-align: center;
        opacity: 0;
        transform:translateY(50px);
        animation-name: heroFadeInUp;
        animation-duration: 0.75s;
        animation-fill-mode: forwards;
        animation-delay: 1.75s;
        transition: transform 0.3s ease-in-out;
        margin: 4rem auto 0;
        width: 100%;
    }

    a.button {
        font-family: "Fira Sans", sans-serif;
        display: inline-block;
        color: #fff;
        font-weight: 500;
        text-decoration: none;
        font-size: 2.4rem;
        padding: 1rem 3rem 1rem 2rem;
        background: var(--orange);
        text-transform: none;

        @include phablet-down {
            transform: translateY(-75%);
        }
    }

    $base: .9rem;

    .chevrons {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 5rem;
        margin-top: 0rem;
        opacity: 0;
        transform:translateY(50px);
        animation-name: heroFadeInUp;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-delay: 3s;

        @include shortscreen {
            display:none;
        }
    }
    
    .chevron {
      position: absolute;
      width: $base * 3.5;
      height: $base * 0.8;
      opacity: 0;
      transform: scale(0.3);
      animation: move-chevron 3s ease-out infinite;
    }
    
    .chevron:first-child {
      animation: move-chevron 3s ease-out 1s infinite;
    }
    
    .chevron:nth-child(2) {
      animation: move-chevron 3s ease-out 2s infinite;
    }
    
    .chevron:before,
    .chevron:after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 50%;
        background: var(--orange);
    }
    
    .chevron:before {
     left: 0;
     transform: skewY(30deg);
    }
    
    .chevron:after {
     right: 0;
     width: 50%;
     transform: skewY(-30deg);
    }
    
    @keyframes move-chevron {
     25% {
      opacity: 1;
        }
     33.3% {
      opacity: 1;
      transform: translateY($base * 3.8);
     }
     66.6% {
      opacity: 1;
      transform: translateY($base * 5.2);
     }
     100% {
      opacity: 0;
      transform: translateY($base * 8) scale(0.5);
     }
    }
}


/* The animation code */
@keyframes heroFadeInUp {
    from { opacity: 0; transform:translateY(50px); }
    to   { opacity: 1; transform:translateY(0px);  }
}