/**
 * components/typography.css
 * 
 * Typography rules.
 *
 */

button,
input[type=submit],
a.button {
    display: inline-block;
    background: var(--orange);
    color: #fff;
    font-size: 2.4rem;
    font-weight: 600;
    padding: 1rem 1.5rem;
    border-radius: 0px 6px 6px 6px;
    text-decoration: none;
    text-align: center;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    @include tablet-down {
        font-size: 1.8rem;
    }

    &.secondary {
        background: #ddd;
        color: var(--dark);
        font-size: 1.8rem;
        padding: 1rem 2rem;

        &:hover,
        &:focus {
            background-color: var(--blue);
        }
    }
}

a.button {
    position: relative;
    padding-right: 2.8rem;

    &.secondary {

        &:not(.previous) {
            padding-right: 3rem;

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 292.359 292.359'%3E%3Cpath fill='%23414141' d='M222.979 133.331L95.073 5.424C91.456 1.807 87.178 0 82.226 0s-9.233 1.807-12.85 5.424c-3.617 3.617-5.424 7.898-5.424 12.847v255.813c0 4.948 1.807 9.232 5.424 12.847 3.621 3.617 7.902 5.428 12.85 5.428 4.949 0 9.23-1.811 12.847-5.428l127.906-127.907c3.614-3.613 5.428-7.897 5.428-12.847 0-4.948-1.813-9.229-5.428-12.846z'/%3E%3C/svg%3E");          
                right: 9px;
            }

            &:hover, 
            &:focus {
                color: #fff;
                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 292.359 292.359'%3E%3Cpath fill='%23fff' d='M222.979 133.331L95.073 5.424C91.456 1.807 87.178 0 82.226 0s-9.233 1.807-12.85 5.424c-3.617 3.617-5.424 7.898-5.424 12.847v255.813c0 4.948 1.807 9.232 5.424 12.847 3.621 3.617 7.902 5.428 12.85 5.428 4.949 0 9.23-1.811 12.847-5.428l127.906-127.907c3.614-3.613 5.428-7.897 5.428-12.847 0-4.948-1.813-9.229-5.428-12.846z'/%3E%3C/svg%3E");
                }
            }
        }

        &.previous {
            padding-left: 3rem;

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 292.359 292.359'%3E%3Cpath fill='%23414141' d='M222.979 133.331L95.073 5.424C91.456 1.807 87.178 0 82.226 0s-9.233 1.807-12.85 5.424c-3.617 3.617-5.424 7.898-5.424 12.847v255.813c0 4.948 1.807 9.232 5.424 12.847 3.621 3.617 7.902 5.428 12.85 5.428 4.949 0 9.23-1.811 12.847-5.428l127.906-127.907c3.614-3.613 5.428-7.897 5.428-12.847 0-4.948-1.813-9.229-5.428-12.846z'/%3E%3C/svg%3E");          
                transform: translateY(-50%) rotate(180deg);
                left: 8px;
            }
    
            &:hover, 
            &:focus {
                color: #fff;
                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 292.359 292.359'%3E%3Cpath fill='%23fff' d='M222.979 133.331L95.073 5.424C91.456 1.807 87.178 0 82.226 0s-9.233 1.807-12.85 5.424c-3.617 3.617-5.424 7.898-5.424 12.847v255.813c0 4.948 1.807 9.232 5.424 12.847 3.621 3.617 7.902 5.428 12.85 5.428 4.949 0 9.23-1.811 12.847-5.428l127.906-127.907c3.614-3.613 5.428-7.897 5.428-12.847 0-4.948-1.813-9.229-5.428-12.846z'/%3E%3C/svg%3E");
                }
            }            
        }

    }

    &:hover,
    &:focus {
        transform: rotate(-2deg) scale(1.05);
    }

    &::after {
        content: '';
        top: 50%;
        transform: translateY(-50%);
        right: 9px;
        height: 12px;
        width: 12px;
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 292.359 292.359'%3E%3Cpath fill='%23fff' d='M222.979 133.331L95.073 5.424C91.456 1.807 87.178 0 82.226 0s-9.233 1.807-12.85 5.424c-3.617 3.617-5.424 7.898-5.424 12.847v255.813c0 4.948 1.807 9.232 5.424 12.847 3.621 3.617 7.902 5.428 12.85 5.428 4.949 0 9.23-1.811 12.847-5.428l127.906-127.907c3.614-3.613 5.428-7.897 5.428-12.847 0-4.948-1.813-9.229-5.428-12.846z'/%3E%3C/svg%3E");
    }
    
}

ul.button-wrapper {
    list-style:none;
    display: flex;
    align-items : center;
    padding: 0;
    margin: 0;

    li {
        padding: 0;
        margin: 0;
        margin-bottom: 0 !important;

        a.button {
            margin-top: 0;
        }
    }

    li + li {
        margin-left: 2rem;
    }
}

ul.button-wrapper,
div.button-wrapper {
    margin-top: 1rem;
    padding-bottom: 1rem;

    @include tiny {
        display: flex;
        flex-direction: column;
    }

    a.button + a.button {
        margin-left: 2rem;

        @include tiny {
            margin-left: 0px;
            margin-top: 2rem;
        }
    }
}