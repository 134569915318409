.c-block--projects {

    h2.block-headline {
        margin-bottom: 8rem;
        text-align: center !important;

        @include mobile {
            padding-top: 4rem;
            margin-bottom: 4rem;
        }
    }

    .moreButton {
        margin-top: 6rem;
    }

    .container {
        @include desktop-up {
            max-width: calc( 100% - 16rem );
            width: $widescreen-width;
        }
    }

    .wrapper {
        display: flex;
        justify-content: center;

        @include phablet-down {
            flex-direction: column;
        }

        &.count-all {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &.count-2 {

            @include tablet-down {
                flex-direction: column;
            }

            div.card {
                padding: 0 3rem;
                margin-bottom: 0;
                min-width: calc( 45% - 4rem );
                max-width: calc( 45% - 4rem );

                @include full-width-tablet-down;

                @include tablet-down {
                    padding: 0;
                }
            }
        }

        div.card {
            display: flex;
            flex-direction: column;
            margin-bottom: 10rem;
            min-width: calc( 33.33% - 4rem );
            max-width: calc( 33.33% - 4rem );
            position : relative;
            opacity : 0;
            transform: translateY(100px);

            @include full-width-mobile;

            @include tablet-down {
                margin-bottom: 5rem !important;

                &:last-of-type {
                    margin-bottom: 2.5rem !important;
                }
            }

            &:hover {
                .image {
                    img {
                        transform: scale(1.04);
                    }
                }
            }

            .image {
                margin: 3rem auto;

                img {
                    transition: transform 0.2s ease;
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }

            .text {
                text-align: center;

                a {
                    text-decoration: none;
                    color: var(--bunker);
                    font-family: var(--headline);
                    font-weight: 900;
                    font-size: 4rem;

                    @include mobile {
                        font-size: 3rem;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                    }

                    &:focus,
                    &:hover {
                        color: var(--orange );
                    }
                }

                .overview {
                    font-weight: 500;
                    margin-top: 2rem;
                    font-size: 2rem;
                }
            }
        }
    }

    &.active {
        .wrapper {
            div.card {
                opacity: 1;
                transform: translateY(0px);
                transition: opacity 0.5s linear 0.25s, transform 0.5s ease 0.25s;

                &:nth-of-type(2) {
                    transition: opacity 0.5s linear 0.65s, transform 0.5s ease 0.65s;
                }
            }
        }
    }
}