/**
 * pages/pages.pcss
 *
 * Styles for news posts
 *
 */

div.page-project {
    margin-bottom: 5rem;

    div.project-background {
        padding-top: 0;

        h2 {
            @include phablet-down {
                margin-bottom: 2rem;
            }

        }

        a.partner {
            color: var(--bunker) !important;
        }

        p.small {
            @include phablet-down {
                text-align: left !important;
                background: #fff;
                padding: 1rem;
            }
        }
    }

    .block-container {
        .c-block:first-of-type {
            &.background-whiteBand {
                padding-top: 10rem;
            }
        }
    }
}


div.page--portfolio {
    .intro-container {
        .c-block {
            padding-top: 0rem;
        }
    }

    div.projects {

        div.card {
            display: flex;
            flex-direction: column;
            position: relative;
            margin-bottom: 10rem;
            opacity: 0;

            &.halfin {
                animation-name: projectsFadeInUp;
                animation-duration: 0.6s;
                animation-fill-mode: forwards;
            }

            .text {
                
                text-align: center;

                a {
                    text-decoration: none;
                    color: var(--bunker);
                    font-family: var(--headline);
                    font-weight: 900;
                    font-size: 6rem;

                    @include phablet-down {
                        font-size: 4rem;
                    }

                    @include mobile {
                        font-size: 3rem;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                    }

                    &:focus,
                    &:hover {
                        color: var(--orange );
                    }
                }

                .overview {
                    font-weight: 500;
                    margin-top: 2rem;
                    font-size: 2rem;
                }
            }

            .image {
                width: 860px;
                max-width: calc( 100% - 4rem );
                margin: 3rem auto;
            }
        }
    }


}


/* The animation code */
@keyframes projectsFadeInUp {
    from { opacity: 0; transform:translateY(80px); }
    to   { opacity: 1; transform:translateY(0px);  }
}



//  div.project {
//      h1 {
//          text-align: center;
//          margin: 0 0 3rem 0;
//      }
//  }